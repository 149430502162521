<template>
  <div class="goods-item-single">
    <div style="margin-right: 10px">
      <EmptyImage
        :inner-style="{ width: '52px', height: '39px' }"
        :outer-style="{ width: '110px', height: '110px' }"
      />
    </div>
    <div class="right">
      <div class="name">{{ item.name }}</div>
      <div v-if="showPrice" :style="priceStyle" class="price">
        <span>￥</span>
        <span>{{ item.price }}</span>
      </div>
      <div v-else-if="showDesc" class="desc">{{ item.desc }}</div>
      <LinkComp v-if="showButton" :link="link">
        <div :style="buttonStyle" class="download-button">{{ buttonStyle.text }}</div>
      </LinkComp>
    </div>
  </div>
</template>

<script>
import mixin from './mixin'
import LinkComp from '../Link'
import EmptyImage from './EmptyImage'

export default {
  name: 'GoodsItemSingle',
  components: { EmptyImage, LinkComp },
  mixins: [mixin]
}
</script>

<style lang="less" scoped>
.goods-item-single {
  position: relative;
  width: 345px;
  height: 109px;
  display: flex;
  .right {
    position: relative;
    width: 225px;
    height: 100%;
    .name {
      width: 100%;
      max-height: 36px;
      font-size: 13px;
      line-height: 18px;
      color: #333333;
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
    .desc {
      margin-top: 10px;
      color: #999999;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .price {
      margin-top: 10px;
      line-height: 1;
      color: #ff6699;
      font-weight: 600;
      height: 25px;
      display: flex;
      align-items: center;
      span:first-of-type {
        font-size: 14px;
      }
      span:last-of-type {
        font-size: 18px;
      }
    }
    .download-button {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      bottom: 1px;
      border: 0.5px solid #ff6699;
      box-sizing: border-box;
      border-radius: 4px;
      color: #ff6699;
      height: 32px;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
