import qs from 'qs'
import { inBiliApp, initEnv, biliBridge } from '@bilibili/js-bridge'
import { MINI_page_show } from '../constants/reportEvents'
import { getCookie, getQuery, replaceUrl } from '@/utils/index'

// 事件上报
// https://info.bilibili.co/pages/viewpage.action?pageId=7545278
class Alita {
  constructor() {
    this.stack = []
    this.ready = true

    this.appInfo = {}
    this.deviceInfo = {}
    this.pageInfo = this.getPageInfo()
    this.getAppInfo()
      .then((info) => {
        this.appInfo = info
        this.ready = true
        this.onReady()
      })
      .catch(() => {
        this.ready = true
        this.onReady()
      })
  }

  send(args, async = true) {
    const data = {
      ...args,
      event: args.event,
      ts: Date.now()
    }

    if (!this.ready) {
      return this.stack.push(data)
    }

    this.sendData(data, async)
  }

  sendData(data, async = true) {
    const { protocol } = window.location
    const url = `${protocol}//cm.bilibili.com/cm/api/conversion/mobile/v2`

    const { request_id: requestId, track_id: trackId } = this.pageInfo || {}
    const { params = {} } = data || {}
    const str = JSON.stringify({
      uploads: [
        {
          ...data,
          ...this.pageInfo,
          ...this.appInfo,
          params: JSON.stringify({
            ...params,
            request_id: requestId,
            track_id: trackId
          })
        }
      ]
    })

    // 预览时不上报
    if (/tetris\/(index|preview)/.test(window.location.href)) return

    if (navigator.sendBeacon) {
      return navigator.sendBeacon(url, str)
    }

    const xhr = new XMLHttpRequest()

    xhr.open('POST', url, async)
    xhr.send(str)
  }

  onReady() {
    if (!this.ready) {
      return
    }

    while (this.stack.length) {
      this.sendData(this.stack.shift())
    }
  }

  // 网页来源信息获取
  getPageInfo() {
    const search = (window.location.search || '')
      .substring(1)
      .replace('creative_id=__CREATIVEID__', 'creative_id=')
      .replace('mid=__MID__', 'mid=')
      .replace('buvid=__BUVID__', 'buvid=')
      .replace('request_id=__REQUESTID__', 'request_id=')
      .replace('track_id=__TRACKID__', 'track_id=')

    const query = qs.parse(search) || {}
    const {
      os = '',
      idfa = '',
      imei = '',
      mid = '',
      buvid = '',
      duid = '',
      request_id: requestId = '',
      track_id: trackId = '',
      android_id: androidId = '',
      creative_id: creativeId
    } = query

    return {
      from: document.referrer,
      creative_id: creativeId,
      android_id: androidId,
      os,
      mid,
      idfa,
      imei,
      buvid,
      duid,
      model: '',
      build_id: '',
      track_id: trackId,
      request_id: requestId,
      url: window.location.href
    }
  }

  getAppInfo() {
    return Promise.all([this.getNativeInfo(), this.getUserInfo()]).then(
      ([nativeInfo, userInfo]) => {
        // const mid = 3289871099691109
        const { mid } = userInfo || {}
        // 从jsb中获取的mid
        window._loginMid = mid || ''
        const { buvid } = nativeInfo || {}
        replaceUrl({ mid, buvid })

        return {
          ...nativeInfo,
          mid
        }
      }
    )
  }

  getDeviceInfo() {
    /* if (!inBiliApp) {
      return null
    } */

    const pageInfo = this.getPageInfo()

    return {
      mid: pageInfo.mid,
      imei: pageInfo.imei,
      buvid: pageInfo.buvid,
      duid: pageInfo.duid,
      idfa: pageInfo.idfa,
      android_id: pageInfo.android_id,
      os: 1
    }
  }

  getUserInfo() {
    if (!inBiliApp) {
      return Promise.resolve({
        mid: getCookie('DedeUserID')
      })
    }

    return new Promise((resolve, reject) => {
      biliBridge.callNative({
        method: 'auth.getUserInfo',
        data: {
          type: 'default'
        },
        callback: (info) => (info && info.mid ? resolve(info) : resolve({}))
      })
    })
  }

  // {
  //     platform: 'ios'|'android',
  //     device: 'phone'|'pad',
  //     build: 6190,
  //     mobi_app: 'iphone',
  //     modelName: 'iPhone XS MAX',
  //     networkstate: 2|1|0,    // 2-WiFi, 1-WWAN, 0-Offline
  //     containerName: 'xxx',
  //     deviceId: 'ABC',
  //     statusBarHeight: 123, //安卓build5345001之后添加，ios5.37之后添加
  //     appKey: '27eb53fc9058f8c3', // ios5.40之后添加，安卓预计5.41添加
  //     buvid: '', //5.42之后添加
  //     channel: '', //渠道 5.42之后添加
  //     osVer: '', // 5.42之后添加
  //     localFingerprint: '', //本地设备指纹   5.44之后添加
  //     fingerprint: '', //设备指纹  5.44之后添加
  // }
  // 安卓和 IOS 返回可能有歧义，需要自己两端兼容好
  getNativeInfo() {
    if (!inBiliApp) {
      return Promise.resolve({
        buvid: getCookie('buvid3')
      })
    }

    const self = this
    return new Promise((resolve, reject) => {
      biliBridge.callNative({
        method: 'global.getContainerInfo',
        callback: function (info) {
          self.deviceInfo = {
            brand: info.brand || '',
            model_name: info.modelName || '',
            platform: info.platform || '',
            os_ver: info.osVer || '',
            mobi_app: info.mobi_app || ''
          }
          return resolve({
            build_id: info.build || '',
            buvid: info.buvid || '',
            model: info.mobi_app || '',
            network: info.networkstate || ''
          })
        }
      })
    })
  }
}
/** 数据上报目前上报组件有：电话组件，咨询组件 **/
// eslint-disable-next-line
;(function () {
  if (inBiliApp) {
    // initEnv 为必须，调用一次即可,兼容旧环境
    initEnv()
  }
  window.alita = new Alita()

  if (!/tetris\/preview/.test(window.location.href)) {
    if (getQuery('is_gaoneng_preload')) {
      return
    }
    window.start_show_time = new Date()
    window.alita.send({ event: MINI_page_show })
  }
})()
