const { protocol, host } = window.location

/**
 * 预发和uat环境前端域名有pre/uat 前缀
 * **/
export const [, domainPrefix = '', gaonengDomain] =
  /^(pre-|uat-)?(gaoneng.bilibili.com)$/.exec(host) || []
// export const [, prefix = '', domain = ''] = /^(\w+-)?(cm.bilibili.com|cm-mng.bilibili.co)$/.exec(host) || []

export const apiHost = gaonengDomain ? 'cm.bilibili.com' : host
export const personApiHost = gaonengDomain ? 'b.bilibili.com' : host
export const isWan = /\.bilibili\.com/.test(host)

export const baseURL = `${protocol}//${apiHost}/mgk/api/web_api/`
export const basePersonalURL = `${protocol}//${personApiHost}/personal-mgk/api/open_api/`
export const baseOpenURL = `${protocol}//${apiHost}/mgk/api/open_api/`
export const cpcBaseURL = `${protocol}//${apiHost}/platform/api/web_api/`
export const accountURL = `${protocol}//${apiHost}/platform/api/open_api/`
export const scvURL = `${protocol}//${apiHost}/scv/api/`

export const creativeHost = host === 'cm-mng.bilibili.co' ? 'cm-mng.bilibili.co' : 'cc.bilibili.com'

// export const gaonengPreviewUrl = gaonengDomain ? '//gaoneng.bilibili.com/tetris/preview/' : `//${host}/tetris/preview/`
export const gaonengFormUrl = gaonengDomain
  ? `//${domainPrefix}gaoneng.bilibili.com/tetris/form/`
  : `//${host}/tetris/form/`
export const mgkURL = gaonengDomain
  ? `${protocol}//cm.bilibili.com/art/bricks`
  : `${protocol}//${host}/art/bricks`
// 三连推广
export const adURL = gaonengDomain
  ? `${protocol}//ad.bilibili.com`
  : `${protocol}//${host}/effect-ads`
// 线索通
export const clueURL = gaonengDomain
  ? `${protocol}//cm.bilibili.com/clue`
  : `${protocol}//${host}/clue`
// 花火
export const huahuoURL = gaonengDomain
  ? `${protocol}//huahuo.bilibili.com`
  : `${protocol}//huahuo.bilibili.co`
export const mgkListUrl = gaonengDomain
  ? `${protocol}//cm.bilibili.com/art/bricks/#/site`
  : `${protocol}//${host}/art/bricks/#/site`
export const mgkModelURL = gaonengDomain
  ? `${protocol}//cm.bilibili.com/art/bricks/#/template_manage`
  : `${protocol}//${host}/art/bricks/#/template_manage`
export const sanlianURL = `${protocol}//${creativeHost}/creative-center/#/assets/video`
// export const sanlianURL = gaonengDomain ? `${protocol}//${domainPrefix}sanlian.bilibili.com` : `${protocol}//${host}/sanlian`
export const uploadURL = `${protocol}//${apiHost}/mgk/api/web_api/v1/landing_pages/upload/pic`
export const mgkLoginURL = gaonengDomain
  ? `${protocol}//cm.bilibili.com/art/bricks#/login`
  : `${protocol}//${host}/art/bricks#/login`

export const appLink = gaonengDomain
  ? `//${apiHost}/ad/#/account?activeTab=package`
  : '/ad/#/account?activeTab=package'
export const transUrl = `${protocol}//${apiHost}/mgk/api/web_api/v1/landing_pages/trans`
