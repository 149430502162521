<template>
  <div class="dropdown-wrap">
    <div v-for="(field, idx) in fields" :key="idx" class="select-wrapper">
      <div @click="onClick(idx)">
        <normal-input
          v-model="pickerInfos[idx].name"
          class="cc-form-input-wrap select-input"
          type="text"
          :readonly="true"
          :placeholder="`请选择${field}`"
        >
        </normal-input>
        <div class="arrow-down van-icon van-icon-arrow-down"></div>
      </div>
      <!--     门店组件优选逻辑升级为匹配距离最近门店有特殊逻辑，成功获取吧获取到经纬度，且开启名单时走到该逻辑-->
      <template
        v-if="
          hasLocation &&
          +idx === 3 &&
          hasNearLocationRole &&
          isGetLocationSuc &&
          nearShopList.length
        "
      >
        <popup-lbs-list
          v-if="pickerInfos[idx].columns.length"
          :ref="`picker${idx}`"
          :default-index="pickerInfos[idx].idx"
          :default-name="pickerInfos[idx].name"
          :columns="nearShopList"
          @confirm="(args) => onConfirm(args, idx, true)"
        ></popup-lbs-list>
      </template>
      <template v-else>
        <popup-picker-single-col
          v-if="pickerInfos[idx].columns.length"
          :ref="`picker${idx}`"
          :columns="pickerInfos[idx].columns"
          :default-index="pickerInfos[idx].idx"
          @confirm="(args) => onConfirm(args, idx)"
        ></popup-picker-single-col>
      </template>
    </div>
    <div v-if="hasLocation" class="agreement">
      <van-checkbox
        v-model="isLocationChecked"
        :checked-color="themeColor"
        shape="square"
        size="small"
        class="help-checkbox"
      />
      <div class="help-msg">
        <span class="tip">自动获取地理位置用于自动填写及匹配信息</span
        ><span class="link" @click="$refs.privacy && $refs.privacy.open()">《授权书》</span>
      </div>
    </div>
    <auth-confirm
      ref="auth"
      @confirm="onLocationConfirm"
      @open="onModalOpen"
      @refuse="onLocationRefuse"
      @read="allowLBS"
    ></auth-confirm>
    <agreement-location ref="privacy" @read="allowLBS"></agreement-location>
  </div>
</template>

<script>
import _ from 'lodash'
import { biliBridge, isSupport, inBiliApp } from '@bilibili/js-bridge'
import NormalInput from './NormalInput'
import PopupPickerSingleCol from './picker/PopupPickerSingleCol'
import PopupLbsList from './picker/PopupLbsList'
import AuthConfirm from './agreements/LocationAuthConfirm'
import AgreementLocation from './agreements/AgreementLocation'
import { replaceUrl, getProbableRes } from '@/utils'
import {
  MINI_lbs_city_code_not_found,
  MINI_lbs_city_code_not_found_ip,
  MINI_lbs_city_code_not_match,
  MINI_lbs_get_location_fail,
  MINI_lbs_get_location_success,
  MINI_lbs_model_no,
  MINI_lbs_model_show,
  MINI_lbs_model_yes
} from '@/canvas/constants/reportEvents'

function treeToArr(data = [], res = [], pid = null) {
  data.forEach((item) => {
    const children = item.children
    res.push(Object.assign(item, pid ? { pid } : {}))
    if (children && children.length) {
      treeToArr(children, res, item.id)
    }
  })
  return res
}

export default {
  name: 'ItemDropdown',
  components: { NormalInput, PopupPickerSingleCol, PopupLbsList, AuthConfirm, AgreementLocation },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    themeColor: {
      type: String,
      default: '#0a8afa'
    },
    hasLocation: {
      type: Boolean,
      default: true
    },
    locationIdxStart: {
      type: Number,
      default: 1
    },
    history: {
      type: Object,
      default: () => ({})
    },
    formId: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      isLocationChecked: false, // 是否允许自动获取地理位置
      isLocationAutoPicked: false, // 是否已自动填充过地理位置
      isLocationConfirmShow: false, // 是否展示过授权确认弹窗
      doShowPicker: () => {}, // 维护一个正常展示picker面板的行为
      curPickerIdx: -1, // 当前点击的选项idx
      pickerInfos: [], // 不同列表信息
      selected: [], // 选中项id的数组
      selectedIdx: [], // 选中项idx的数组
      lng: undefined, // 经纬度
      lat: undefined, // 经纬度
      isGetLocationSuc: false, // 是否成功获取到经纬度
      nearShopList: [], // 临近门店列表
      hasNearLocationRole: false, // 是否开启临近门店功能
      useIpLocation: 0 // ip兜底逻辑
    }
  },
  computed: {
    options() {
      return this.data.options
    },
    optionsObj() {
      try {
        return JSON.parse(this.options)
      } catch (e) {
        return {}
      }
    },
    optionsInfo() {
      return treeToArr(this.initChildren)
    },
    fields() {
      return this.optionsObj.fields.map((i) => i.name) || []
    },
    levels() {
      return this.fields.length
    },
    initChildren() {
      return this.optionsObj.children
    }
  },
  watch: {
    isLocationChecked(val) {
      replaceUrl({ allow_lbs: Number(!!val) })
    },
    history(newValue, oldValue) {
      if (oldValue.isEmpty) {
        this.isLocationChecked = !!newValue.is_lbs_authorized
      }
    }
  },
  created() {
    this.genSelectedIdx()
    this.initPickerInfo()
    this.hasNearLocationRole = !!window?._globalData?.isShowDistanceLbs || false
    this.useIpLocation = !!window?._globalData?.useIpLocation || 0
    this.data.levels = this.levels
  },
  methods: {
    genSelectedIdx() {
      let children = this.initChildren
      this.selected = this.optionsObj.default || []
      this.selectedIdx = this.selected.map((id) => {
        const findItem = children.find((c) => c.id === id) || {}
        const findIdx = children.indexOf(findItem)
        children = findItem.children || []
        return findIdx
      })
      this.fillSelectedIdx()
      this.onChange()
    },
    initPickerInfo() {
      this.pickerInfos = new Array(this.levels).fill({ columns: [], ids: [], idx: 0, name: '' })
      this.refreshCols()
      if (this.hasLocation) {
        this.autoPick(0, 0)
      }
    },
    refreshCols() {
      let children = this.initChildren
      // 是否成功获取到经纬度
      this.pickerInfos = this.pickerInfos.map((item, pickerIdx) => {
        if (
          this.hasLocation &&
          this.lng &&
          this.lat &&
          this.isGetLocationSuc &&
          this.hasNearLocationRole &&
          +pickerIdx === 3 &&
          this.nearShopList.length
        ) {
          const idx = this.selectedIdx[pickerIdx]
          item.columns = this.nearShopList.map((i) => i.name)
          item.ids = this.nearShopList.map((i) => i.name)
          item.weights = this.nearShopList.map(() => 0)
          item.idx = idx
          item.name = item.columns[idx]
          return { ...item }
        } else {
          const idx = this.selectedIdx[pickerIdx]
          const data = this.transferData(children)
          item.columns = data.map((i) => i.name)
          item.ids = data.map((i) => i.id)
          item.weights = data.map((i) => i.weight)
          item.idx = idx
          item.name = item.columns[idx]
          children = children[idx]?.children || []
          // 地理位置需要手动选择时不会自动填充，当选择省份的时候不支持自动填充
          if (
            !(this.hasLocation && !this.isLocationAutoPicked) &&
            children.length === 1 &&
            pickerIdx !== 1
          ) {
            this.selectedIdx[pickerIdx + 1] = 0
          }
          return { ...item }
        }
      })
    },
    transferData(nodes) {
      // 去重
      const idSet = new Set()
      const res = []
      nodes.forEach((node) => {
        if (!idSet.has(node.id)) {
          idSet.add(node.id)
          res.push({
            id: node.id,
            name: node.name,
            weight: node.w || 0
          })
        }
      })
      return res
    },
    onConfirm({ idx }, pickerIdx, isNearLbs = false) {
      // idx为-1说明是自动选择的，地位后获取的id可能不在选项中
      if (idx === -1) {
        this.dedupReport(MINI_lbs_city_code_not_match)
        if (this.curPickerIdx === pickerIdx) {
          this.doShowPicker()
        }
        return
      }

      if (this.selectedIdx[pickerIdx] === idx) return
      this.selectedIdx[pickerIdx] = idx
      this.clearNext(pickerIdx + 1)
      // 最近近门店逻辑单独处理
      const getNearList = async () => {
        const cityId =
          (this.pickerInfos[2] &&
            this.pickerInfos[2].ids &&
            this.pickerInfos[2].ids.length &&
            this.pickerInfos[2].ids[idx]) ||
          ''
        const shopList = await this.getShopNearestList(this.lng, this.lat, cityId).catch((e) =>
          console.log(e)
        )
        this.nearShopList = shopList || []
        this.refreshCols()
        this.onChange()
      }
      if (
        this.lat &&
        this.lng &&
        this.isGetLocationSuc &&
        this.hasNearLocationRole &&
        +pickerIdx === 2
      ) {
        getNearList()
      } else {
        this.refreshCols()
        isNearLbs ? this.onChangeNearLbs() : this.onChange()
      }
      if (+pickerIdx === 0 && this.isLocationAutoPicked) {
        if (this.pickerInfos[idx].name !== undefined || this.pickerInfos[idx].name !== '') {
          this.getChangeBusLocation()
        }
      }
    },
    onChangeNearLbs() {
      const infos = this.getSelectedInfos()
      let index = 1
      const nearShopInfo = {
        name: this.pickerInfos[3].name || '',
        id: index++ || '',
        level: 4
      }
      infos[3] = nearShopInfo
      this.data.value = infos
        .map((i) => i?.name)
        .filter((i) => !!i)
        .join(',')
      this.data.code = infos
        .map((i) => i?.id)
        .filter((i) => !!i)
        .join(',')
      if (this.data.levels === this.selectedIdx.filter((i) => i >= 0).length) {
        this.$emit('change')
      }
    },
    onChange() {
      const infos = this.getSelectedInfos()
      this.data.value = infos
        .map((i) => i?.name)
        .filter((i) => !!i)
        .join(',')
      this.data.code = infos
        .map((i) => i?.id)
        .filter((i) => !!i)
        .join(',')
      // 已选完全部levels
      if (this.data.levels === this.selectedIdx.filter((i) => i >= 0).length) {
        this.$emit('change')
      }
    },
    dedupReport(event) {
      if (!this[event]) {
        this[event] = true
        this.$reportUI(event)
      }
    },
    onClick(pickerIdx) {
      this.curPickerIdx = pickerIdx
      this.$emit('focus')

      const normalPick = () => {
        try {
          const ref = this.$refs[`picker${pickerIdx}`][0]
          ref && ref.open()
        } catch (e) {
          console.log(e)
        }
      }
      this.doShowPicker = () => {
        normalPick()
        this.doShowPicker = () => {}
      }
      // 未自动填充过时，已选择车型，且点击其他选项时，获取位置信息
      if (
        this.hasLocation &&
        !this.isLocationAutoPicked &&
        this.selectedIdx[0] !== undefined &&
        this.selectedIdx[this.locationIdxStart] === undefined &&
        this.selectedIdx[pickerIdx] === undefined &&
        pickerIdx >= this.locationIdxStart
      ) {
        if (this.isLocationChecked) {
          this.getLocation()
          return
        }
        if (!this.isLocationConfirmShow) {
          this.$refs.auth.open()
          return
        }
        normalPick()
      }
      normalPick()
    },
    clearNext(start) {
      for (let lv = start; lv < this.levels; lv++) {
        this.selectedIdx[lv] = undefined
      }
      this.refreshCols()
    },
    fillSelectedIdx() {
      const fillPart = new Array(this.levels - this.selectedIdx.length).fill(undefined)
      this.selectedIdx = [...this.selectedIdx, ...fillPart]
    },
    getSelectedInfos() {
      const infos = []
      let lodashGetStr = ''
      this.selectedIdx.forEach((v, lv) => {
        if (lv === 0) {
          lodashGetStr += `[${v}]`
        } else {
          lodashGetStr += `.children[${v}]`
        }
        const info = _.get(this.initChildren, lodashGetStr)
        infos.push(info)
      })
      return infos
    },
    getChangeBusLocation() {
      const doLBS = async () => {
        const lng = this.lng
        const lat = this.lat
        // 临近门店特殊处理
        if (this.hasNearLocationRole) {
          const { china_admin_code: cityId, shop_name: providerName } =
            await this.getCityIdAndNearest(lng, lat).catch(() => ({}))
          if (cityId) {
            const shopList = await this.getShopNearestList(lng, lat, cityId).catch(() => ({}))
            if (shopList && shopList.length) {
              this.nearShopList = shopList
              const provinceId = cityId.slice(0, 2) + '0000'
              this.pickFromCoor(this.locationIdxStart, provinceId)
              this.pickFromCoor(this.locationIdxStart + 1, cityId)
              this.nearAutoPick(this.locationIdxStart + 2, providerName)
            } else {
              this.$reportUI(MINI_lbs_city_code_not_found)
              this.doShowPicker()
            }
          }
        } else {
          const { china_admin_code: cityId } = await this.getCityId(lng, lat).catch(() => ({}))
          if (cityId) {
            const provinceId = cityId.slice(0, 2) + '0000'
            this.pickFromCoor(this.locationIdxStart, provinceId)
            this.pickFromCoor(this.locationIdxStart + 1, cityId)
            this.randomAutoPick(this.locationIdxStart + 2)
          } else {
            this.$reportUI(MINI_lbs_city_code_not_found)
            this.doShowPicker()
          }
        }
      }
      if (this.isLocationChecked && this.isGetLocationSuc && this.lat && this.lng) {
        doLBS()
      }
    },
    getLocation() {
      this.isLocationAutoPicked = true
      // todo
      const doLBS = async (lng, lat) => {
        // 临近门店特殊处理
        if (this.lat && this.lng && this.isGetLocationSuc && this.hasNearLocationRole) {
          const { china_admin_code: cityId, shop_name: providerName } =
            await this.getCityIdAndNearest(lng, lat).catch(() => ({}))
          if (cityId) {
            const shopList = await this.getShopNearestList(lng, lat, cityId).catch(() => ({}))
            if (shopList && shopList.length) {
              this.nearShopList = shopList
              const provinceId = cityId.slice(0, 2) + '0000'
              this.pickFromCoor(this.locationIdxStart, provinceId)
              this.pickFromCoor(this.locationIdxStart + 1, cityId)
              this.nearAutoPick(this.locationIdxStart + 2, providerName)
            } else {
              this.$reportUI(MINI_lbs_city_code_not_found)
              this.doShowPicker()
            }
          }
        } else {
          const { china_admin_code: cityId } = await this.getCityId(lng, lat).catch(() => ({}))
          if (cityId) {
            const provinceId = cityId.slice(0, 2) + '0000'
            this.pickFromCoor(this.locationIdxStart, provinceId)
            this.pickFromCoor(this.locationIdxStart + 1, cityId)
            this.randomAutoPick(this.locationIdxStart + 2)
          } else {
            this.$reportUI(MINI_lbs_city_code_not_found)
            this.doShowPicker()
          }
        }
      }
      const doIpLBS = async () => {
        const { china_admin_code: cityId } = await this.getIpCityId().catch(() => ({}))
        if (cityId) {
          const provinceId = cityId.slice(0, 2) + '0000'
          this.pickFromCoor(this.locationIdxStart, provinceId)
          this.pickFromCoor(this.locationIdxStart + 1, cityId)
          this.randomAutoPick(this.locationIdxStart + 2)
        } else {
          this.$reportUI(MINI_lbs_city_code_not_found_ip)
          this.doShowPicker()
        }
      }
      if (inBiliApp) {
        isSupport('ability.getLocation').then((support) => {
          if (support) {
            biliBridge.callNative({
              method: 'ability.getLocation',
              data: {
                bizName: '4',
                type: 'real',
                hintMsg:
                  '我们可能会获取您的位置信息用于本次及后续表单自动填写。不授权该权限不影响App其他功能使用。'
              },
              callback: async (info) => {
                const { location, code } = info || {}
                if (code === 0) {
                  const coor = location?.coordinate?.coor
                  const lat = coor.split(',')[0]
                  const lng = coor.split(',')[1]
                  this.lat = lat
                  this.lng = lng
                  if (this.lat && this.lng) {
                    this.isGetLocationSuc = true
                  }
                  doLBS(lng, lat)
                  this.$reportUI(MINI_lbs_get_location_success)
                } else {
                  if (this.useIpLocation) {
                    doIpLBS()
                  }
                  // this.doShowPicker()
                  this.$reportUI(MINI_lbs_get_location_fail)
                }
              }
            })
          } else {
            doLBS()
          }
        })
      } else {
        doLBS()
      }
    },
    async getCityId(longtitude = '', latitude = '') {
      return this.$request
        .get(
          'v1/address/lng_lat' +
            `?lng=${longtitude.trim()}&lat=${latitude.trim()}&form_id=${this.formId}&form_item_id=${
              this.data.id
            }`
        )
        .then((res) => {
          return Promise.resolve(res.data)
        })
        .catch((err) => {
          console.log('err', err)
          return Promise.reject(new Error(err))
        })
    },
    async getIpCityId() {
      return this.$request
        .get('v1/address/ip')
        .then((res) => {
          console.log('res', res)
          return Promise.resolve(res.data)
        })
        .catch((err) => {
          console.log('err', err)
          return Promise.reject(new Error(err))
        })
    },

    async getCityIdAndNearest(longtitude = '', latitude = '') {
      let product = this.pickerInfos[0]?.name || ''
      product = product.replace('+', '%2B')
      return this.$request
        .get(
          'v1/address/nearest' +
            `?lng=${longtitude.trim()}&lat=${latitude.trim()}&form_id=${this.formId}&form_item_id=${
              this.data.id
            }&product=${product}`
        )
        .then((res) => {
          return Promise.resolve(res.data)
        })
        .catch((err) => {
          console.log('err', err)
          return Promise.reject(new Error(err))
        })
    },
    async getShopNearestList(longtitude = '', latitude = '', cityId = '') {
      let product = this.pickerInfos[0]?.name || ''
      product = product.replace('+', '%2B')
      return this.$request
        .get(
          'v1/address/nearest/service/list' +
            `?lng=${longtitude.trim()}&lat=${latitude.trim()}&form_id=${this.formId}&form_item_id=${
              this.data.id
            }&china_admin_code=${cityId}&product=${product}`
        )
        .then((res) => {
          const { data = [] } = res || {}
          console.log('data', data)
          const list = data.map((item) => ({
            name: item.shop_name || '',
            id: item.shop_name || '',
            distance: item.distance || '',
            address: item.shop_address || '',
            w: 0,
            children: []
          }))
          return Promise.resolve(list)
        })
        .catch((err) => {
          console.log('err', err)
          return Promise.reject(new Error(err))
        })
    },
    getInfoFromId(id) {
      return this.optionsInfo.find((i) => +i.id === +id) || {}
    },
    getIdxFromName(pickerIdx, name) {
      const columns = this.pickerInfos[pickerIdx].columns
      const found = columns.find((c) => c === name)
      return columns.indexOf(found)
    },
    autoPick(pickerIdx, idx) {
      this.onConfirm({ idx }, pickerIdx)
    },
    nearAutoPick(pickerIdx, name) {
      const idx = this.nearShopList.findIndex((item) => item.name === name)
      this.autoPick(pickerIdx, idx)
    },
    randomAutoPick(pickerIdx) {
      const columns = this.pickerInfos[pickerIdx].columns
      if (columns.length === 0) return

      const weights = this.pickerInfos[pickerIdx].weights
      const totalWeights = weights.reduce((a, b) => a + b, 0)

      let randomIdx = 0
      if (totalWeights === 0) {
        randomIdx = Math.floor(Math.random(0, 1) * columns.length)
      } else {
        const probabilities = columns.map((c, idx) => {
          return weights[idx] / totalWeights
        })
        randomIdx = getProbableRes(probabilities)
      }

      this.autoPick(pickerIdx, randomIdx)
    },
    pickFromCoor(pickerIdx, id) {
      const info = this.getInfoFromId(id)
      let idx = this.getIdxFromName(pickerIdx, info.name)
      // 自动填充不能找到时取省下级的其他城市填充
      if (this.hasLocation && +pickerIdx === 2 && idx === -1) {
        const columns = this.pickerInfos[pickerIdx].columns
        if (columns.length) {
          idx = Math.floor(Math.random() * columns.length)
        }
      }
      this.autoPick(pickerIdx, idx)
    },
    onLocationConfirm() {
      this.allowLBS()
      this.getLocation()
      this.$refs.auth && this.$refs.auth.close()
      this.$reportUI(MINI_lbs_model_yes)
    },
    onLocationRefuse() {
      this.$reportUI(MINI_lbs_model_no)
    },
    onModalOpen() {
      this.isLocationConfirmShow = true
      this.$reportUI(MINI_lbs_model_show)
    },
    allowLBS() {
      this.isLocationChecked = true
    }
  }
}
</script>

<style lang="less" scoped>
.dropdown-wrap {
  width: 100%;
  .select-wrapper {
    position: relative;
    width: 100%;
    height: 44px;
    margin-bottom: 12px;
    &:last-of-type {
      margin-bottom: 0 !important;
    }
    .arrow-down {
      position: absolute;
      line-height: 16px;
      top: 14px;
      right: 14px;
    }
  }
  .select-input {
    padding-right: 30px;
  }
}
.agreement {
  display: flex;
  align-content: center;
  .help-checkbox {
    margin-right: 3px;
  }
  .help-msg {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #999999;
    .tip,
    .link {
      display: inline-block;
    }
    .link {
      color: #0a8afa;
      cursor: pointer;
    }
  }
}
</style>
