var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown-wrap"},[_vm._l((_vm.fields),function(field,idx){return _c('div',{key:idx,staticClass:"select-wrapper"},[_c('div',{on:{"click":function($event){return _vm.onClick(idx)}}},[_c('normal-input',{staticClass:"cc-form-input-wrap select-input",attrs:{"type":"text","readonly":true,"placeholder":`请选择${field}`},model:{value:(_vm.pickerInfos[idx].name),callback:function ($$v) {_vm.$set(_vm.pickerInfos[idx], "name", $$v)},expression:"pickerInfos[idx].name"}}),_c('div',{staticClass:"arrow-down van-icon van-icon-arrow-down"})],1),(
        _vm.hasLocation &&
        +idx === 3 &&
        _vm.hasNearLocationRole &&
        _vm.isGetLocationSuc &&
        _vm.nearShopList.length
      )?[(_vm.pickerInfos[idx].columns.length)?_c('popup-lbs-list',{ref:`picker${idx}`,refInFor:true,attrs:{"default-index":_vm.pickerInfos[idx].idx,"default-name":_vm.pickerInfos[idx].name,"columns":_vm.nearShopList},on:{"confirm":(args) => _vm.onConfirm(args, idx, true)}}):_vm._e()]:[(_vm.pickerInfos[idx].columns.length)?_c('popup-picker-single-col',{ref:`picker${idx}`,refInFor:true,attrs:{"columns":_vm.pickerInfos[idx].columns,"default-index":_vm.pickerInfos[idx].idx},on:{"confirm":(args) => _vm.onConfirm(args, idx)}}):_vm._e()]],2)}),(_vm.hasLocation)?_c('div',{staticClass:"agreement"},[_c('van-checkbox',{staticClass:"help-checkbox",attrs:{"checked-color":_vm.themeColor,"shape":"square","size":"small"},model:{value:(_vm.isLocationChecked),callback:function ($$v) {_vm.isLocationChecked=$$v},expression:"isLocationChecked"}}),_c('div',{staticClass:"help-msg"},[_c('span',{staticClass:"tip"},[_vm._v("自动获取地理位置用于自动填写及匹配信息")]),_c('span',{staticClass:"link",on:{"click":function($event){_vm.$refs.privacy && _vm.$refs.privacy.open()}}},[_vm._v("《授权书》")])])],1):_vm._e(),_c('auth-confirm',{ref:"auth",on:{"confirm":_vm.onLocationConfirm,"open":_vm.onModalOpen,"refuse":_vm.onLocationRefuse,"read":_vm.allowLBS}}),_c('agreement-location',{ref:"privacy",on:{"read":_vm.allowLBS}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }