<template>
  <div class="image-carousel-translate-display">
    <resize-observer v-if="inEditor" @notify="handleResize" />
    <swiper
      ref="mySwiper"
      :options="Object.assign(swiperOptions, config)"
      class="swiper-no-swiping"
    >
      <swiper-slide v-for="(item, index) in items" :key="index">
        <CcImageV2
          :image="item.image"
          :link="item.link"
          :report-info="{ img_num: index }"
          empty-img-width="26.6%"
        />
      </swiper-slide>
      <div slot="pagination" class="swiper-pagination"></div>
    </swiper>
  </div>
</template>
<script>
import CcImageV2 from '../components/ImageV2'
import mixins from '../mixins/imageCarousel.js'

export default {
  name: 'ImageCarousel',
  components: {
    CcImageV2
  },
  mixins: [mixins],
  data() {
    return {
      swiperOptions: {
        loop: true,
        noSwiping: true
      }
    }
  }
}
</script>
<style lang="less" scoped>
.image-carousel-translate-display {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white !important;
  z-index: 0;
}

.swiper-container {
  height: 100%;
  overflow: hidden;
}

.swiper-wrapper {
  width: 100% !important;
  height: 100% !important;
}

.swiper-slide {
  width: 80% !important;
  margin-right: 20%;
  background-color: #f5f5f5 !important;
}

.swiper-slide-next {
  left: -16%;
}
</style>
