<template>
  <CcButton :class="buttonClass" :content="content" :style="style" />
</template>
<script>
import CcButton from '../components/Button'

export default {
  name: 'ButtonComp',
  components: {
    CcButton
  },
  provide() {
    return {
      compName: this.component.name,
      compId: this.component.id
    }
  },
  props: {
    component: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    data() {
      return this.component.data
    },
    content() {
      return this.data?.content || {}
    },
    style() {
      const { borderWidth, borderRadius, fontSize, ...rest } = this.data?.style || {}
      return {
        borderRadius: borderRadius + 'px',
        borderWidth: borderWidth + 'px',
        fontSize: fontSize + 'px',
        ...rest
      }
    },
    buttonClass() {
      const { animation } = this.data?.style || {}
      return `animation-${animation}`
    }
  }
}
</script>
