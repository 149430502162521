<template>
  <div
    :class="{ 'no-logo': !showLogo, 'no-content': !showContent }"
    :style="bgStyle"
    class="cc-download clearfix"
  >
    <div v-if="showContent" class="app-content">
      <div :style="nameStyle" class="app-name">
        {{ app.name || defaultApp.name || '此处填写应用名称' }}
      </div>
      <div class="app-desc">
        <div :style="descStyle" class="app-text">
          {{
            app.description ||
            defaultApp.description ||
            '此处填写应用描述，应用描述内容支持两行显示'
          }}
        </div>
      </div>
    </div>
    <div v-else-if="!bgStyle" class="empty">
      <img src="../../assets/image_empty.png" alt="" />
    </div>
    <template v-if="showLogo">
      <img v-if="app.logo" :src="webpSafe(app.logo)" class="app-logo" />
      <div v-else class="app-logo empty" />
    </template>
    <div class="app-button">
      <ButtonComp
        :class="buttonClass"
        :content="buttonContent"
        :style="buttonStyle"
        :download="download"
      />
    </div>
  </div>
</template>
<script>
import ButtonComp from './Button'
import { webpSafe } from '../utils'
import { getQuery } from '@/utils'

export default {
  name: 'CcDownload',
  components: {
    ButtonComp
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    showLogo: {
      type: Boolean,
      default: true
    },
    showContent: {
      type: Boolean,
      default: true
    },
    defaultApp: {
      type: Object,
      default: () => ({})
    },
    download: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      webpSafe
    }
  },
  computed: {
    content() {
      return this.data?.content || {}
    },
    app() {
      return this.data?.app || {}
    },
    compStyle() {
      return this.data?.compStyle || {}
    },
    nameStyle() {
      const { nameColor: color } = this.compStyle
      return {
        color
      }
    },
    descStyle() {
      const { descColor: color } = this.compStyle
      return {
        color
      }
    },
    bgStyle() {
      const { background } = this.compStyle || {}
      const style = {
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }

      if (/^http(s)?:\/\//.test(background)) {
        style.backgroundImage = `url(${webpSafe(background)})`
      } else {
        style.backgroundColor = background
      }

      return background ? style : null
    },
    buttonStyle() {
      const overrideStyle = {}
      // 审核时下载组件标注
      if (getQuery('is_audit')) {
        overrideStyle.borderColor = 'red'
        overrideStyle.borderWidth = '2px'
      }
      const { borderWidth, borderRadius, fontSize, ...rest } = this.data?.buttonStyle || {}

      return {
        borderRadius: borderRadius + 'px',
        borderWidth: borderWidth + 'px',
        fontSize: fontSize + 'px',
        ...rest,
        ...overrideStyle
      }
    },
    buttonClass() {
      const { animation } = this.data?.buttonStyle || {}
      return `animation-${animation}`
    },
    buttonContent() {
      return {
        link: this.app.link,
        text: this.content.buttonText
      }
    }
  }
}
</script>
<style lang="less" scoped>
.cc-download {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 12px 12px;
  background-color: white;
  .app-logo {
    float: left;
    width: 46px;
    height: 46px;
    margin-left: -100%;
    border-radius: 4px;
    &.empty {
      background-color: #f5f5f5;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 22px 16px;
      background-image: url('../../assets/image_empty.png');
    }
  }
  &.no-logo .app-content {
    padding: 0 120px 0 0;
  }
  &.no-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #f5f5f5;
    .empty {
      width: calc(100% - 100px);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 53.33px;
        height: 40px;
      }
    }
    .app-button {
      margin: 0 !important;
    }
  }
  .app-content {
    float: left;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0 120px 0 56px;
    .app-name {
      color: #333;
      font-size: 13px;
      line-height: 14px;
      margin-bottom: 4px;
      font-weight: 500;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .app-desc {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: flex-end;
      height: 28px;
      .app-text {
        display: -webkit-box;
        -webkit-box-orient: block-axis;
        -webkit-line-clamp: 2;
        max-height: 28px;
        word-break: break-all;
        overflow: hidden;
        line-height: 14px;
        color: #666;
        font-size: 12px;
      }
    }
  }
  .app-button {
    float: left;
    width: 100px;
    height: 40px;
    margin: 3px 0 3px -100px;
  }
}
</style>
