<template>
  <div v-if="isEditor" class="float-form-panel-editor">
    <div class="content">
      <div class="title-area">
        <div class="title">{{ title }}</div>
        <div class="close-icon van-icon van-icon-cross"></div>
      </div>
      <div v-if="data" :class="{ 'is-editor': isEditor }" class="form-area">
        <FormComp :component="formSetting" :is-float="true" context="editor"></FormComp>
      </div>
      <privacy context="editor"></privacy>
    </div>
  </div>
  <base-popup v-else v-model="showDialog" position="bottom">
    <div class="content">
      <div class="title-area">
        <div class="title">{{ title }}</div>
        <div class="close-icon van-icon van-icon-cross" @click="showDialog = false"></div>
      </div>
      <div v-if="data" ref="formArea" class="form-area" :style="formAreaStyle">
        <FormComp
          :component="formSetting"
          :container="formContainer"
          :form-info="formInfo"
          :is-float="true"
          :show="showDialog"
          @mounted="formContainer = $refs.formArea"
          @close-float="showDialog = false"
        ></FormComp>
      </div>
      <privacy :init-show-privacy="showPrivacy"></privacy>
    </div>
  </base-popup>
</template>

<script>
import FormComp from '../../form/Component'
import Privacy from './Privacy'
import { MINI_close_float_form } from '../../constants/reportEvents'
import { getAppZoom, isLongIOS } from '../../utils'
import BasePopup from '../base/BasePopup'

export default {
  name: 'FloatFormPanel',
  components: { Privacy, FormComp, BasePopup },
  props: {
    isEditor: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => ({})
    },
    showModal: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      formContainer: null,
      loaded: false,
      formInfo: null,
      showPrivacy: false,
      showDialog: false,
      containerInfo: {
        clientHeight: document.documentElement.clientHeight || window.screen.height
      }
    }
  },
  computed: {
    behavior() {
      return this.data?.behavior || {}
    },
    formId() {
      return this.behavior?.formId
    },
    title() {
      const { form } = this.behavior || {}
      const { useTitle, title } = form || {}
      return useTitle ? title : ''
    },
    formSetting() {
      const { formId, floatFormData, form } = this.behavior || {}
      const { successMessage } = form || {}
      return {
        data: {
          form: {
            id: formId,
            title: '',
            successMessage
          },
          ...floatFormData
        }
      }
    },
    formAreaStyle() {
      let diff = 44 // title height
      if (this.showPrivacy) {
        diff += 30
      }
      if (isLongIOS) {
        diff += 34
      }
      return {
        maxHeight: (this.containerInfo.clientHeight * 0.8) / getAppZoom() - diff + 'px',
        overflow: 'auto'
      }
    }
  },
  watch: {
    'showModal.show'(val) {
      if (val && !this.isEditor) {
        if (this.loaded) {
          this.showDialog = true
        } else {
          this.queryFormData()
        }
      }
    },
    showDialog(val) {
      this.showModal.show = val
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.containerInfo.clientHeight =
        document.documentElement.clientHeight || window.screen.height
    })
  },
  methods: {
    beforeClose(done) {
      this.$reportUI(MINI_close_float_form, { formId: this.formId })
      done()
    },
    queryFormData() {
      if (!this.formId) return
      const getPrivacy = () => {
        return this.$request
          .get('v1/form/person_info/on_off')
          .then(() => {
            return true
          })
          .catch(() => {
            return false
          })
      }

      const getForm = () => {
        return this.$request
          .get(`v1/form/${this.formId}`)
          .then((res) => {
            return res.data
          })
          .catch(() => {
            this.$toast('获取表单失败')
          })
      }

      Promise.all([getForm(), getPrivacy()]).then(([formInfo, showPrivacy]) => {
        this.formInfo = formInfo
        this.showPrivacy = showPrivacy
        this.loaded = true
        this.showDialog = true
      })
    }
  }
}
</script>

<style lang="less" scoped>
.van-popup {
  border-radius: 0 !important;
  width: 375px;
  margin: 0 auto;
  left: 0;
  right: 0;
}
.content {
  background-color: white;
  .title-area {
    width: 100%;
    height: 44px;
    border-bottom: 1px solid #e7e7e7;
    .title {
      font-size: 14px;
      line-height: 44px;
      text-align: center;
      font-weight: bold;
      color: #212121;
    }
    .close-icon {
      position: absolute;
      right: 15px;
      top: 13px;
      font-size: 18px;
      color: #757575;
      cursor: pointer;
    }
  }
  .form-area {
    position: relative;
    width: 100%;
    overflow-y: scroll;
    background-color: white;
    max-height: calc(100vh * 0.8);
    text-align: left;
    &.is-editor {
      overflow: hidden;
      max-height: 667px * 0.8;
    }
  }
  .bottom-privacy {
    width: 100%;
    bottom: 0;
    left: 0;
  }
}

.float-form-panel-editor {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  pointer-events: none;
  .content {
    position: relative;
    width: 100%;
  }
}
</style>
