<template>
  <div v-if="isForce" class="wx-modal-body-manual">
    <div class="text">{{ data.text }}</div>
    <div class="account">微信号：{{ account }}</div>
    <div v-if="isEditor" class="button">
      {{ buttonText }}
    </div>
    <div v-else class="button" @click="onClickForce">
      {{ buttonText }}
    </div>
  </div>
  <div v-else class="wx-modal-body-auto">
    <img
      src="https://i0.hdslb.com/bfs/activity-plat/static/20220613/350f34a338378c33628aa40ad0c3ae8e/0wvyx5imvA.svg"
      alt=""
    />
    <div class="title">复制成功</div>
    <div class="text">{{ data.text }}</div>
    <div class="account">微信号：{{ account }}</div>
    <div class="button" @click="onClickUnforce">{{ buttonText }}</div>
  </div>
</template>

<script>
import { inBiliApp, biliBridge, isSupport } from '@bilibili/js-bridge'
import {
  MINI_copy_wx_modal_show,
  MINI_copy_wx_modal_close,
  MINI_copy_wx_modal_click
} from '../../constants/reportEvents'
import { isAndroid, isCmWebview } from '../../utils'

const DEFAULT_URL = 'weixin://'

export default {
  name: 'WxModalBody',
  inject: ['pageQuery', 'pageId'],
  props: {
    data: {
      type: Object,
      default: null
    },
    isEditor: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
    packageId: {
      type: Number,
      default: 0
    },
    reportInfo: {
      type: Object,
      default: null
    },
    isForce: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    buttonText() {
      return this.isForce ? '复制微信号' : this.data.buttonText
    },
    account() {
      return this.isEditor ? '3URFGEGXB' : window._globalData.wxAccount
    }
  },
  watch: {
    show(val) {
      val ? this.onModalShow() : this.onModalClose()
    }
  },
  mounted() {
    this.onModalShow()
  },
  methods: {
    onClickForce() {
      this.$reportUI(MINI_copy_wx_modal_click, this.reportInfo)
      this.copy()
    },
    onClickUnforce() {
      this.$reportUI(MINI_copy_wx_modal_click, this.reportInfo)
      this.openWx()
    },
    onModalShow() {
      this.$reportUI(MINI_copy_wx_modal_show, this.reportInfo)
    },
    onModalClose() {
      this.$reportUI(MINI_copy_wx_modal_close, this.reportInfo)
    },
    copy() {
      this.$emit('copy')
    },
    openWx() {
      const self = this
      const url = this.data.jumpUrl || DEFAULT_URL
      const jumpLink = isAndroid ? '' : url // 双端降级逻辑不一致。ios：降级url和唤起url保持一致，防止跳转到web；安卓：降级url置为空

      const notJSBCallup = () => {
        this.$emit('report', 1) // 降级唤起时始终上报
        document.location.href = url
        setTimeout(() => {
          document.location.href = url
        }, 0)
      }
      if (!isCmWebview()) {
        return notJSBCallup()
      }
      if (inBiliApp) {
        isSupport('cm.callUpApp').then((support) => {
          if (support) {
            biliBridge.callNative({
              method: 'cm.callUpApp',
              data: {
                schemaUrl: url,
                jumpLink
              },
              callback: (res) => {
                if (res.status === 1) {
                  self.$emit('report', 1)
                }
              }
            })
          } else {
            notJSBCallup()
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.button {
  width: 232px;
  height: 44px;
  text-align: center;
  background: #1aad19;
  border-radius: 4px;
  font-size: 16px;
  line-height: 44px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.wx-modal-body-auto {
  width: 280px;
  height: 211px;
  border-radius: 8px;
  padding-top: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin-bottom: 6px;
  }
  .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #18191c;
    margin-bottom: 6px;
  }
  .text,
  .account {
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #61666d;
    margin-bottom: 10px;
  }
  .account {
    width: 232px;
    font-size: 11px;
    line-height: 15px;
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.wx-modal-body-manual {
  width: 280px;
  height: 188px;
  border-radius: 8px;
  padding-top: 24px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  .text {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #18191c;
    margin-bottom: 22px;
  }
  .account {
    width: 232px;
    height: 36px;
    background: #f4f4f4;
    border-radius: 4px;
    font-size: 12px;
    line-height: 36px;
    text-align: center;
    color: #61666d;
    margin-bottom: 24px;
    padding: 0 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
