<template>
  <CcImage
    :image="image"
    :link="link"
    :download="download"
    :is-android-image-download="isAndroidImageDownload"
  />
</template>
<script>
import CcImage from '../components/Image'
import { isAndroid } from '../utils'

export default {
  name: 'IImage',
  components: {
    CcImage
  },
  provide() {
    return {
      compName: this.component.name,
      compId: this.component.id
    }
  },
  props: {
    component: {
      type: Object,
      default: null
    },
    context: {
      type: String,
      default: ''
    },
    download: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isAndroidImageDownload:
        isAndroid && this.component.name.indexOf('button-download-image') === 0
    }
  },
  computed: {
    data() {
      return this.component.data
    },
    content() {
      return this.data?.content || {}
    },
    link() {
      return this.content?.link || []
    },
    image() {
      return this.content?.image || ''
    }
  },
  watch: {
    image(src) {
      this.onImageChange(src)
    }
  },
  methods: {
    getImageLayout(image) {
      return new Promise((resolve) => {
        const img = new Image()

        img.onload = () => {
          const { width, height } = img

          img.onload = null
          resolve(width / height)
        }

        img.onerror = () => resolve(0)
        img.src = image
      })
    },
    onImageChange(image) {
      const { width, height } = this.data?.layout || {}

      this.getImageLayout(image).then((ratio) => {
        if (!ratio) {
          return
        }

        const currHeight = Math.ceil(width / ratio)

        if (height !== undefined) {
          this.data.layout.height = currHeight
        }

        setTimeout(() => this.$emit('block-auto-expand'))
      })
    }
  }
}
</script>
<style lang="less" scoped>
.component-button-wrap {
  position: relative;
  box-sizing: border-box;

  width: 100%;
  height: 100%;
  text-align: center;

  .component-image-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #f5f5f5;

    &.empty {
      .empty-image {
        width: 76px;
        height: 57px;
      }
    }
  }
}
</style>
