import md5 from 'md5'
import { types } from './constants'
import { getPageId } from '../../utils'
import { isUndefined } from 'lodash'

export function toParams(params = {}) {
  const qs = []
  const data = { appkey: 'fd', ts: Date.now() }
  const ks = [
    'appkey',
    'device_param',
    'form_datas',
    'mgk_page_id',
    'mid',
    'phone_channel',
    'pn',
    'ts',
    'verify_code',
    'ttcode',
    'wechat_package_datas'
  ]
  const keys = []

  for (let i = 0, l = ks.length; i < l; i++) {
    const key = ks[i]

    if (key === 'appkey' || key === 'ts') {
      keys.push(key)
    } else if (params[key] !== undefined) {
      keys.push(key)
      data[key] = params[key]
    }
  }

  for (let i = 0, l = keys.length; i < l; i++) {
    const k = keys[i]
    const v = data[k] || ''

    qs.push(`${k}=${v}`)
  }

  data.sign = md5(qs.join('&') + '61467fd0d2511eee78decf6b36837fce')

  return data
}

export function toBase(val) {
  return window.btoa(unescape(encodeURIComponent(val)))
}

export function validate(form) {
  return false
}

export function formatFormItem(formItem, inputStyle = 1) {
  const {
    radio_checkbox_val: radioCheckboxOptions,
    placeholder: originalPlaceholder,
    ...rest
  } = formItem || {}
  const { type } = rest || {}
  const placeholderPrefix = inputStyle === 3 && !formItem.is_allow_empty ? '必填 ' : ''
  const PREFIX = '请输入'

  let placeholder = ''
  switch (type) {
    case 'address':
      placeholder = PREFIX + '详细地址'
      break
    default:
      break
  }

  // 姓名,电话,身份证,填空题 使用自定义占位符
  if (type === 'input' || type === 'telephone') {
    placeholder = originalPlaceholder
  } else if (type === 'idcard') {
    // 兼容老表单
    placeholder = originalPlaceholder || '请输入身份证号'
  }

  if (inputStyle === 3) {
    placeholder = formItem.label
  }

  let parsedOptions
  try {
    parsedOptions = JSON.parse(radioCheckboxOptions || '[]') || []
    if (typeof parsedOptions === 'string') throw new Error()
  } catch (e) {
    parsedOptions = []
  }

  const data = {
    ...rest,
    error: '',
    placeholder: placeholderPrefix + placeholder,
    options: [types.DROPDOWN, types.LBS, types.CUSTOMIZE_LBS_DROP_DOWN].includes(type)
      ? rest.options
      : parsedOptions
  }

  const checkedItems = parsedOptions.filter((item) => !!item.checked)
  if (checkedItems.length > 0) {
    if (type === 'radio') {
      data.value = checkedItems[0].text
    } else if (type === 'checkbox') {
      data.value = checkedItems.map((item) => item.text)
    }
  }

  if (type === 'telephone') {
    data.extra_value = ''
  } else if (type === 'address') {
    data.address = {
      province: {},
      city: {},
      county: {},
      detail: ''
    }
  }
  // 实验默认填充
  if (type === 'input' && !data.value && rest.item_default) {
    data.value = rest.item_default
  }

  return data
}

export function formatFormItemForSubmit(formItem) {
  const {
    id,
    type,
    label,
    validate,
    empty,
    address,
    value,
    extra_value: extraValue = '',
    phone_channel: phoneChannel,
    encrypted_phone_vo: encryptedPhoneVo,
    code
  } = formItem
  const data = {
    id,
    name: id,
    label,
    type,
    validate,
    empty,
    value,
    extra_value: extraValue,
    code
  }

  if (type === 'address') {
    data.value = JSON.stringify(address)
  }

  if (type === 'telephone') {
    if (phoneChannel !== undefined) {
      data.phone_channel = phoneChannel
    }
    if (encryptedPhoneVo !== undefined) {
      data.encrypted_phone_vo = encryptedPhoneVo
    }
  }

  if (type === 'radio') {
    data.code = formItem.options.find((i) => i.text === data.value)?.code
  }

  if (type === 'checkbox') {
    data.code = (data.value || [])
      .map((v) => {
        return formItem.options.find((i) => i.text === v)?.code
      })
      .filter((i) => !!i)
      .join(',')
  }
  if (type === 'lbs_dropdown' || type === 'dropdown') {
    if (window.__CUTOMIZE__ !== 'geely') {
      data.code = []
    }
  }

  if (Array.isArray(value)) {
    data.value = value.join(';')
  }

  return data
}

export function serializeForm(formId, formItems = [], form = {}) {
  const formDatas = formItems.map(formatFormItemForSubmit)
  const params = {
    form_id: formId,
    form_datas: formDatas
  }

  if (form.customize) {
    params.customize = form.customize
  }

  return JSON.stringify(
    toParams({
      form_datas: toBase(JSON.stringify(params))
    })
  )
}

export function serializeTelCode(pn, code = '', extra = {}) {
  const { phone_channel: phoneChannel, mid } = extra
  let innerParams = { pn: toBase(pn) }
  if (!isUndefined(phoneChannel)) {
    innerParams = { ...innerParams, phone_channel: phoneChannel }
  }
  if (!isUndefined(mid)) {
    innerParams = { ...innerParams, mid }
  }
  if (code) {
    innerParams = { ...innerParams, ttcode: code.trim() }
  }
  const params = toParams(innerParams)

  return JSON.stringify({
    code: toBase(JSON.stringify(params))
  })
}

export function serialize(key, valueObj = {}) {
  return JSON.stringify(
    toParams({
      [key]: toBase(JSON.stringify(valueObj))
    })
  )
}

export function serializePhone() {
  const deviceParams = window.alita.getDeviceInfo()

  if (!deviceParams) {
    return
  }

  return JSON.stringify(
    toParams({
      device_param: toBase(JSON.stringify(deviceParams)),
      mgk_page_id: getPageId()
    })
  )
}

export function serializeBPhone() {
  return JSON.stringify(
    toParams({
      mid: window.alita?.appInfo?.mid,
      mgk_page_id: getPageId()
    })
  )
}

export function serializePhoneExt(ext = {}) {
  const deviceParams = window.alita.getDeviceInfo()

  if (!deviceParams) {
    return
  }

  return JSON.stringify(
    toParams({
      device_param: toBase(
        JSON.stringify({
          ...deviceParams,
          ...ext
        })
      )
    })
  )
}

export function getCardAge(identityCard) {
  const len = (identityCard + '').length
  if (len === 0) {
    return 0
  } else {
    // 身份证号码只能为15位或18位其它不合法
    if (len !== 15 && len !== 18) {
      return 0
    }
  }
  let strBirthday = ''
  // 处理18位的身份证号码从号码中得到生日和性别代码
  if (len === 18) {
    strBirthday =
      identityCard.substr(6, 4) +
      '/' +
      identityCard.substr(10, 2) +
      '/' +
      identityCard.substr(12, 2)
  }
  if (len === 15) {
    strBirthday =
      '19' +
      identityCard.substr(6, 2) +
      '/' +
      identityCard.substr(8, 2) +
      '/' +
      identityCard.substr(10, 2)
  }
  // 时间字符串里，必须是“/”
  const birthDate = new Date(strBirthday)
  const nowDateTime = new Date()
  let age = nowDateTime.getFullYear() - birthDate.getFullYear()
  // 再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
  if (
    nowDateTime.getMonth() < birthDate.getMonth() ||
    (nowDateTime.getMonth() === birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())
  ) {
    age--
  }
  return age
}

export function IdentityCodeValid(code) {
  const city = {
    11: '北京',
    12: '天津',
    13: '河北',
    14: '山西',
    15: '内蒙古',
    21: '辽宁',
    22: '吉林',
    23: '黑龙江 ',
    31: '上海',
    32: '江苏',
    33: '浙江',
    34: '安徽',
    35: '福建',
    36: '江西',
    37: '山东',
    41: '河南',
    42: '湖北 ',
    43: '湖南',
    44: '广东',
    45: '广西',
    46: '海南',
    50: '重庆',
    51: '四川',
    52: '贵州',
    53: '云南',
    54: '西藏 ',
    61: '陕西',
    62: '甘肃',
    63: '青海',
    64: '宁夏',
    65: '新疆',
    71: '台湾',
    81: '香港',
    82: '澳门',
    91: '国外 '
  }
  let pass = true
  if (code === '' || code === null || code === undefined) {
    return true
  }
  const reg =
    /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$)/i
  if (!code || !reg.test(code)) {
    pass = false
  } else if (!city[code.substr(0, 2)]) {
    pass = false
  }
  if (code.length === 18) {
    code = code.toUpperCase()
    code = code.split('')
    const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
    const parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2]
    let sum = 0
    let ai = 0
    let wi = 0
    for (let i = 0; i < 17; i++) {
      ai = code[i]
      wi = factor[i]
      sum += ai * wi
    }
    // eslint-disable-next-line eqeqeq
    if (parity[sum % 11] != code[17]) {
      pass = false
    }
  }
  return pass
}

export const getOffsetTop = (ele, container = document.body) => {
  let height = 0
  do {
    height += ele.offsetTop
    ele = ele.offsetParent
  } while (ele !== container)
  return height
}
