<template>
  <CcButton
    :class="buttonClass"
    :content="content"
    :style="style"
    :download="download"
    :is-auto="isAuto"
  />
</template>
<script>
import { getQuery } from '@/utils'
import CcButton from '../components/Button'

export default {
  name: 'DownloadButton',
  components: {
    CcButton
  },
  provide() {
    return {
      compName: this.component.name,
      compId: this.component.id
    }
  },
  props: {
    component: {
      type: Object,
      default: () => ({})
    },
    download: {
      type: Object,
      default: () => null
    },
    isAuto: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    data() {
      return this.component.data
    },
    content() {
      return this.data?.content || {}
    },
    style() {
      // 审核时下载组件标注
      const overrideStyle = {}
      if (getQuery('is_audit')) {
        overrideStyle.borderColor = 'red'
        overrideStyle.borderWidth = '2px'
      }
      const { borderWidth, borderRadius, fontSize, ...rest } = this.data?.style || {}
      return {
        borderRadius: borderRadius + 'px',
        borderWidth: borderWidth + 'px',
        fontSize: fontSize + 'px',
        ...rest,
        ...overrideStyle
      }
    },
    buttonClass() {
      const { animation } = this.data?.style || {}
      return `animation-${animation}`
    }
  }
}
</script>
