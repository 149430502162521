<template>
  <div class="cc-form-msg">
    {{ countOptions.prefix }}
    <span
      :class="{ red: countOptions.order === 'desc', blue: countOptions.order !== 'desc' }"
      :style="{ color: color }"
      >{{ countOptions.count }}
    </span>
    {{ countOptions.suffix }}
  </div>
</template>

<script>
import mixin from './mixin'

export default {
  name: 'CountDefault',
  mixins: [mixin],
  props: {
    countOptions: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="less" scoped>
.cc-form-msg {
  margin-top: 6px;
  font-size: 14px;
  font-weight: normal;
  color: #666666;
  .red {
    color: red;
  }
  .blue {
    color: #0a8afa;
  }
}
</style>
