import Vue from 'vue'
const noop = () => {}

function dialog(options) {
  const {
    overlay = true,
    overlayClass = '',
    closeOnClickOverlay,
    width = 830,
    title = '',
    render,
    round = true,
    component,
    okText = '确定',
    cancelText = '取消',
    className = '',
    closeable = true,
    el,
    lockScroll,
    position,
    duration,
    transition,
    attrs = {},
    provide = {}
  } = options

  const props = {
    round,
    title,
    width,
    duration,
    overlay,
    position,
    transition,
    overlayClass,
    closeOnClickOverlay,
    transitionAppear: true,
    safeAreaInsetBottom: true,
    lockScroll: lockScroll || !el,
    value: true,
    closeable
  }

  const data = {
    loading: false,
    visible: true,
    disabled: false,
    width,
    title,
    value: true
  }

  const iClassName = `i-popup i-popup-${position || 'center'} ${className}`

  const instance = new Vue({
    provide,
    data,
    methods: {
      onOk(data) {
        options.onOk && options.onOk(data)
        this.destroy()
      },
      onCancel() {
        options.onCancel && options.onCancel()
        this.destroy()
      },
      onClose() {
        options.onClose && options.onClose()
        this.destroy()
      },
      onClick() {
        options.onClick && options.onClick()
      },
      destroy() {
        data.loading = false

        this.$el.parentElement?.removeChild(this.$el)
        this.$destroy()
      }
    },
    render(h) {
      const btns = options.buttons || [
        {
          id: 'ok',
          name: okText,
          type: 'primary'
        },
        {
          id: 'cancel',
          plain: true,
          type: 'primary',
          name: cancelText
        }
      ]

      const renderBody = (h) => {
        const t =
          title &&
          h(
            'div',
            {
              class: 'i-popup-header'
            },
            title
          )

        const b =
          component === undefined
            ? render(h)
            : h(component, {
                ref: 'body',
                class: 'i-popup-body',
                props: options.props || {},
                on: {
                  confirm: (data) => this.onOk(data),
                  cancel: () => this.onCancel()
                }
              })

        const f =
          btns && btns.length
            ? h(
                'div',
                {
                  class: 'i-popup-footer'
                },
                btns.map((item) => {
                  return h(
                    'van-button',
                    {
                      props: {
                        tag: 'a', // a 标签会自动上报
                        type: item.type,
                        size: item.size,
                        plain: item.plain,
                        color: item.color,
                        text: item.name,
                        round: item.round,
                        disabled: item.id === 'ok' ? data.disabled : false,
                        loading: item.id === 'ok' ? data.loading : false
                      },
                      class: {
                        'i-button-group': btns.length > 1,
                        vm: true
                      },
                      on: {
                        click: () => {
                          const { id } = item || {}
                          const name = `on${id[0].toUpperCase()}${id.substr(1)}`

                          if (id === 'ok' && this.$refs.body && this.$refs.body.confirm) {
                            data.loading = true

                            const func = this.$refs.body.confirm()

                            if (func && func.then) {
                              return func
                                .then((data) => (options.onOk ? options.onOk(data) : noop()))
                                .then(() => this.destroy())
                                .catch(() => {
                                  data.loading = false
                                })
                            } else {
                              options.onOk ? options.onOk(func) : noop()
                              this.destroy()
                              return
                            }
                          }

                          const callback = (options[name] || noop)()

                          if (!callback || !callback.then) {
                            return this.destroy()
                          }

                          data.loading = true
                          callback
                            .then(() => this.destroy())
                            .catch(() => {
                              // const msg = error && error.message !== undefined ? error.message : error
                              data.loading = false
                              // msg && Vue.prototype.$Message.error(msg)
                            })
                        }
                      }
                    },
                    item.name
                  )
                })
              )
            : null

        return h(
          'div',
          {
            class: 'i-popup-content'
          },
          [t, b, f]
        )
      }

      return h('van-popup', {
        props,
        style: options.style,
        class: iClassName,
        attrs,
        on: {
          input: (show) => {
            if (!show) {
              this.destroy()
              options.onCancel && options.onCancel()
            }
          },
          cancel: () => this.onCancel(),
          confirm: (data) => this.onOk(data),
          close: () => this.onClose(),
          click: () => this.onClick(),
          'click-close-icon': () => this.onClose(),
          'click-overlay': () => closeOnClickOverlay && this.onClose()
        },
        scopedSlots: {
          default: () => renderBody(h)
        }
      })
    }
  })

  const comp = instance.$mount()
  const parent = el || document.body

  parent.appendChild(comp.$el)

  return instance
}

function open(options) {
  return dialog(options)
}

function alert(options) {
  const contentRender = (h) => h('div', { class: 'i-popup-body' }, options.content || '')
  const render = options.render || contentRender

  return dialog({
    ...options,
    render,
    buttons: [{ id: 'ok', name: options.okText || '确定', type: 'primary' }]
  })
}

function confirm(options) {
  const contentRender = (h) => h('div', { class: 'i-popup-body' }, options.content || '')
  const render = options.render || contentRender

  return dialog({ ...options, render })
}

export default {
  alert,
  confirm,
  open
}
