<template>
  <base-popup v-model="visible" :max-zoom="1.7" position="bottom" has-header modal-style>
    <template v-if="visible">
      <popup-header @close="close">个人信息授权与保护声明</popup-header>
      <div class="content-wrapper">
        <div class="content">
          <!-- <div class="title">个人信息授权与保护声明</div> -->
          <div class="bold">高能建站提醒您：</div>
          <br />
          <div class="bold">
            一、本页面由广告主或其代理方（简称“广告客户”）利用广告落地页建站工具（简称“落地页工具”）提供的技术制作，高能建站（幻电科技（上海）有限公司）作为“落地页工具”技术服务的提供方，仅基于广告客户的委托和指令代为收集和处理您的个人信息。因广告客户提供的相关功能、服务产生的一切问题，由广告客户负责处理并承担全部法律责任。请您仔细阅读广告客户在本页面上展示的个人信息授权与保护声明，确保在完全同意相关内容的基础上提交您的信息。
          </div>
          <template v-if="isGoTest">
            <br />
            <div class="bold">广告客户:{{ customerName }}</div>
          </template>
          <br />
          <div class="bold">
            二、如对广告客户收集、处理您的个人信息有任何问题，可通过高能建站（<span class="url"
              >help@bilibili.com</span
            >）获取广告客户的联系方式。
          </div>
          <br />
          <div class="center bold">个人信息授权与保护声明</div>
          <br />
          <div>
            1、为给您提供完善的服务，{{
              customerName
            }}（“我们”）可能会收集与您有关的个人信息，我们将按照本协议的规定收集、处理您的个人信息。<span
              class="bold"
              >本协议可能涉及您个人信息权益的重要约定，请您在提供个人信息前仔细阅读。您一旦主动在页面填写您的个人信息并进行提交操作，即意味着您同意我们按照本协议的相关内容处理您的信息。如您不同意本协议，请勿进行下一步操作。</span
            >
          </div>
          <br />
          <div>
            2、为了进一步向您提供商品或服务的相关信息，了解您的需求以更好地实现本广告的推广目的、参与相关营销活动等，需要您按照页面的表单提交相关信息，我们承诺将严格依照相关法律规定收集、处理您提交的个人信息。<span
              class="bold"
              >您有权拒绝提供上述信息，但可能无法获取进一步的广告信息、或者无法正常使用相应的服务、功能或者无法达到服务拟达到的效果。
            </span>
          </div>
          <br />
          <div>
            <span class="bold"
              >3、您同意我们可基于以下目的或原因自行委托、安排第三方对您的个人信息进行收集和/或处理（这些第三方包括但不限于我们的经销商、广告服务合作方、客服服务商、数据分析服务商等）：（1）安排销售人员与您取得联系，向您提供更有价值的信息，实现广告推广目的；（2）用户广告投放、广告投放效果优化、广告归因、广告监测等广告业务合作；（3）用于商业性分析和洞察；（4）检验信息真实性；（5）其他法律法规许可的目的。我们会要求这些第三方按照不低于我们安全标准保护您的个人信息。</span
            >
          </div>
          <br />
          <div>
            4、我们将严格保护您的个人信息安全。我们将采用适当制度、安全技术和程序等措施来保护您的个人信息不被未经授权的访问、使用或泄漏。<span
              class="bold"
              >因我们及我们委托的第三方收集、处理您的个人信息产生的一切问题，由我们处理、负责。</span
            >
          </div>
          <br />
          <div>
            <span class="bold"
              >5、对于不满14周岁的用户，须在其监护人陪同阅读和理解本协议并且经监护人许可的情况下向我们提交相关个人信息。</span
            >
          </div>
          <br />
          <div>
            6、如果您有任何投诉、建议或咨询，或由任何基于法律法规规定的权利请求，例如需要查询、修改或更正、撤销授权您的个人信息，或对个人信息保护问题有任何疑问或投诉，可通过高能建站（<span
              class="url"
              >help@bilibili.com</span
            >）获取我们的联系方式。
          </div>
          <br />
          <div>
            <span class="bold"
              >7、我们会适时对本声明进行修订，该等修订构成本声明的一部分。我们会将修订后的声明发布在落地页。在该种情况下，若您继续提供您的个人信息，即视为您同意接受修订后的声明的约束。</span
            >
          </div>
        </div>
        <div class="button-area">
          <div class="button" @click="close">我已阅读</div>
        </div>
      </div>
    </template>
  </base-popup>
</template>

<script>
import BasePopup from '../../base/BasePopup'
import PopupHeader from '../../base/PopupHeader'

export default {
  name: 'AgreementPrivacy',
  components: { BasePopup, PopupHeader },
  data() {
    return {
      visible: false,
      customerName: window._globalData?.customerName || '广告客户',
      isGoTest: false
    }
  },
  mounted() {
    const { external_link_warning = '' } = window._extraData || {}
    const { customerName = '' } = window._globalData || {}
    if (external_link_warning && customerName && customerName.length <= 17) {
      this.isGoTest = true
    }
  },

  methods: {
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.content-wrapper {
  width: 100%;
  height: 393px;
  padding-top: 0;
  border-radius: 10px 10px 0 0 !important;
  .content {
    height: 306px;
    overflow-y: auto;
    padding: 16px 16px 0;
    color: #666666;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    .title {
      font-size: 18px;
      line-height: 25px;
      color: #000000;
      margin-bottom: 20px;
    }
    .sub-title {
      margin-top: 24px;
      margin-bottom: 26px;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
    .paragraph {
      margin-top: 24px;
      margin-bottom: 26px;
    }
    .url {
      color: #0a8afa;
    }
    .center {
      text-align: center;
    }
    .bold {
      font-weight: bold;
      color: #313332;
    }
    .title {
      font-size: 18px;
      line-height: 25px;
      color: #000000;
      margin-bottom: 20px;
    }
  }
  .agreement {
    cursor: pointer;
    color: #0a8afa;
  }
  .button-area {
    width: 100%;
    height: 68px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    .button {
      width: 343px;
      height: 44px;
      font-size: 16px;
      line-height: 44px;
      color: white;
      text-align: center;
      cursor: pointer;
      background: rgba(255, 102, 153, 1);
      border-radius: 22px;
    }
  }
}
</style>
