<template>
  <div
    v-if="showPrivacy"
    class="button-agreement-privacy"
    :class="{ 'no-padding': initShowPrivacy }"
  >
    <div class="text">
      <span>{{ companyName }}</span>
      <span class="agreement" @click="$refs.modal.open()">《个人信息保护声明》</span>
    </div>
    <AgreementPrivacy ref="modal"></AgreementPrivacy>
  </div>
</template>

<script>
import AgreementPrivacy from './agreements/AgreementPrivacy'

export default {
  name: 'PrivacyComp',
  components: { AgreementPrivacy },
  props: {
    context: {
      type: String,
      default: ''
    },
    initShowPrivacy: {
      type: Boolean,
      default: false
    },
    isGetExtraData: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showPrivacy: false,
      companyName: '提交即视为您已阅读并同意'
    }
  },
  created() {
    this.getPrivacyOnOff()
  },
  watch: {
    isGetExtraData() {
      this.setCustomerName()
    }
  },
  mounted() {
    this.setCustomerName()
  },
  methods: {
    setCustomerName() {
      const { external_link_warning = '' } = window._extraData || {}
      const { customerName = '' } = window._globalData || {}
      if (external_link_warning && customerName && customerName.length <= 17) {
        this.companyName = customerName
      }
    },
    getPrivacyOnOff() {
      if (this.context === 'editor' || this.initShowPrivacy) {
        this.showPrivacy = true
        return
      }
      this.$request
        .get('v1/form/person_info/on_off')
        .then((res) => {
          this.showPrivacy = !!res.data
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }
}
</script>

<style lang="less" scoped>
.button-agreement-privacy {
  width: 100%;
  height: 30px;
  color: #999999;
  display: flex;
  align-items: center;
  background-color: white;
  &.no-padding {
    padding-top: 0;
  }
  .text {
    width: 100%;
    font-size: 12px;
    transform: scale(0.833);
    line-height: 12px;
    text-align: center;
  }
  .agreement {
    color: #0a8afa !important;
    cursor: pointer;
  }
}
</style>
