<template>
  <div v-if="!form || !form.id" ref="form" class="cc-form no-padding">
    <img style="width: 100%" class="form-placeholder" :src="placeholder" @load="onLayoutChange" />
    <!--div class="default-back" :style="{backgroundImage: `url(${placeholder})`}" /-->
    <!--van-empty v-else description="请选择表单"  /-->
  </div>
  <div v-else :id="form.id" ref="form">
    <IForm
      class="cc-form"
      :action="action"
      :class="className"
      :container="container"
      :context="context"
      :form-info="formInfo"
      :is-float="isFloat"
      :show="show"
      :style="formStyle"
      :data="data"
      @change="onLayoutChange"
      @submit="onSubmit"
    />
    <link-no-float v-if="link" ref="link" class="form-hidden-link" :link="link" />
  </div>
</template>
<script>
import IForm from '../components/form/index'
import LinkNoFloat from '../components/LinkNoFloat'

const placeholders = {
  'form-simple':
    'https://i0.hdslb.com/bfs/activity-plat/static/20210407/350f34a338378c33628aa40ad0c3ae8e/yuSW3H1k0w.png',
  'form-normal':
    'https://i0.hdslb.com/bfs/activity-plat/static/20210407/350f34a338378c33628aa40ad0c3ae8e/tlXPu5cZKA.png',
  'form-question':
    'https://i0.hdslb.com/bfs/activity-plat/static/20210527/d72cd055e5397bb5587804649580b870/1LVVIckidy.png',
  'form-float':
    'https://i0.hdslb.com/bfs/activity-plat/static/20220418/350f34a338378c33628aa40ad0c3ae8e/a2GS2F6L7x.png'
}

export default {
  name: 'FormComp',
  components: {
    IForm,
    LinkNoFloat
  },
  provide() {
    return {
      compName: this.component.name,
      compId: this.component.id
    }
  },
  props: {
    component: {
      type: Object,
      default: null
    },
    context: {
      type: String,
      default: ''
    },
    isFloat: {
      type: Boolean,
      default: false
    },
    container: {
      type: HTMLDivElement,
      default: null
    },
    action: {
      type: Object,
      default: null
    },
    formInfo: {
      type: Object,
      default: null
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    placeholder() {
      const { name } = this.component || {}

      if (this.isFloat) {
        return placeholders['form-float']
      }
      return placeholders[name] || placeholders['form-normal']
    },
    data() {
      return this.component.data
    },
    form() {
      return this.data?.form || {}
    },
    link() {
      const link = this.data?.advanceSetting?.link || {}
      const { status, behavior } = link || {}
      const { scheme, url, ios } = behavior || {}
      if (!status || (!scheme && !url && !ios?.id)) {
        return
      }

      return link
    },
    formStyle() {
      const { background, ...style } = this?.data?.style || {}
      const isBackgroundImage = /^http(s)?:\/\//.test(background)

      return {
        ...style,
        backgroundImage: isBackgroundImage ? `url(${background})` : '',
        backgroundColor: isBackgroundImage ? '' : background
      }
    },
    className() {
      const { inputStyle = 1 } = this.formStyle || {}

      return `form-input-style-${inputStyle}`
    }
  },
  watch: {
    'data.form.title'() {
      this.onLayoutChange()
    },
    'data.advanceSetting.count.status'() {
      this.onLayoutChange()
    },
    // 主题色变更
    'data.style.themeColor'(color) {
      this.onThemeColorChange(color)
    }
  },
  mounted() {
    this.$emit('mounted')
  },
  methods: {
    onSubmit() {
      this.$toast(this.form?.successMessage || '提交成功')
      this.$refs.link && this.$refs.link.onClick()
      if (this.isFloat) {
        this.$emit('close-float')
      }
    },
    /***
     * 仅在编辑器中需要调整高度，实际页面以设置为准
     * **/
    onLayoutChange() {
      if (this.context !== 'editor') {
        return
      }

      // 浮层表单没有布局概念
      if (this.isFloat) {
        return
      }

      this.$nextTick(() => {
        if (!this.$refs.form) {
          return
        }

        this.data.layout.height = this.$refs.form.offsetHeight
        this.$nextTick(() => {
          this.$emit('block-auto-expand')
        })
      })
    },
    onThemeColorChange(color) {
      if (this.context !== 'editor') {
        return
      }

      // 跟随主题色变化
      this.data.buttonStyle.borderColor = color
      this.data.buttonStyle.backgroundColor = color

      const { locationButton, count } = this.data.advanceSetting || {}
      if (locationButton) {
        this.data.advanceSetting.locationButton.color = color
      }
      if (count) {
        this.data.advanceSetting.count.color = color
      }
    }
  }
}
</script>
<style scoped lang="less">
.no-padding {
  padding: unset;
}
.default-back {
  width: 100%;
  height: 334px;
  background-size: contain;
  background-repeat: no-repeat;
}
.form-hidden-link {
  width: 0;
  height: 0;
}
</style>
