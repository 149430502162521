<template>
  <base-popup v-model="show" position="bottom" class="lbs-popup">
    <div class="lbs-popup-wrap">
      <div class="lbs-popup-header">
        <span class="popup-cancel" @click="onCancel">取消</span>
        <span class="popup-title">门店选择</span>
        <span class="popup-yes" @click="onConfirm">确定</span>
      </div>
      <div class="lbs-popup-content">
        <ul class="lbs-list">
          <van-radio-group v-model="checked">
            <li
              v-for="(item, index) in columns"
              :key="item.id"
              class="lbs-list-item"
              @click="onSelect(item, index)"
            >
              <div class="lbs-list-item-content">
                <div class="item-content">
                  <div class="lbs-list-item-wrap">
                    <div class="lbs-list-item-title">{{ item.name }}</div>
                  </div>
                  <div class="lbs-list-item-desc">
                    <div class="list-item-distance">
                      <img class="location-icon" src="../../../../assets/location.png" alt="" />{{
                        item.distance
                      }}km<span class="address-desc">{{ item.address }}</span>
                    </div>
                  </div>
                </div>
                <div class="item-select">
                  <div class="item-select-icon">
                    <van-radio
                      :name="item.name"
                      icon-size="16px"
                      checked-color="#FF6699"
                    ></van-radio>
                  </div>
                </div>
              </div>
              <div class="item-select-line"></div>
            </li>
          </van-radio-group>
        </ul>
      </div>
    </div>
  </base-popup>
</template>
<script>
// import { get } from 'lodash'
import mixin from './mixin'

export default {
  name: 'LbsPopup',
  mixins: [mixin],
  props: {
    textField: {
      type: String,
      default: 'name'
    },
    idField: {
      type: String,
      default: 'id'
    },
    childrenField: {
      type: String,
      default: 'children'
    },
    defaultName: {
      type: String,
      default: ''
    }
    // levels: {
    //   type: Number,
    //   required: true
    // }
  },
  data() {
    return {
      checked: 1,
      idx: 0
    }
  },
  watch: {
    columns(val) {
      if (val.length) {
        this.checked = val[0].name
        this.idx = 0
      }
    },
    defaultName(val) {
      if (val) {
        this.init()
      }
    }
  },
  created() {
    this.init()
    // this.cols = this.transferData(this.columns)
  },
  methods: {
    init() {
      if (this.defaultName && this.columns.length > 0) {
        this.checked = this.defaultName
        this.idx = this.columns.findIndex((item) => item.name === this.defaultName)
      } else {
        this.checked = this.columns[0].name
        this.idx = 0
      }
    },
    open() {
      this.show = true
    },
    close() {
      this.show = false
    },
    onCancel() {
      this.close()
      this.$emit('cancel')
    },
    onConfirm() {
      this.close()
      this.$emit('confirm', {
        names: this.checked,
        idx: this.idx
      })
    },
    onSelect(item, index) {
      this.checked = item.name
      this.idx = index
    }
    // getIds(values) {
    //   const ids = []
    //
    //   let lodashGetStr = ''
    //   values.forEach((v, lv) => {
    //     if (lv === 0) {
    //       lodashGetStr += `[${v}]`
    //     } else {
    //       lodashGetStr += `.${[this.childrenField]}[${v}]`
    //     }
    //     const id = get(this.columns, lodashGetStr + `.${this.idField}`)
    //     ids.push(id)
    //   })
    //   return ids
    // }
  }
}
</script>

<style scoped lang="less">
.van-popup {
  width: 375px;
  margin: 0 auto;
  left: 0;
  right: 0;
  border-radius: 0 !important;
}
.lbs-popup {
  border-radius: 12px 12px 0 0 !important;
}
.lbs-popup-wrap {
  height: 430px;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  font-family: 'PingFang SC';
  width: 100%;
}
.lbs-popup-content {
  overflow-y: scroll;
  height: 380px;
  width: 100%;
}
.lbs-popup-header {
  font-style: normal;
  height: 50px;
  padding: 16px 16px 12px;
  box-shadow: inset 0px -0.5px 0px #e3e5e7;
  border-radius: 12px 12px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.popup-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #18191c;
}
.popup-cancel {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #61666d;
}
.popup-yes {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #ff6699;
}
.lbs-list-item {
  overflow: hidden;
}
.lbs-list-item-content {
  display: flex;
  box-sizing: border-box;
  padding: 12px 16px 12px 16px;
}

.lbs-list-item-title {
  color: #18191c;
  font-size: 14px;
  font-weight: bold;
  width: 285px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lbs-list-item-desc {
  display: flex;
  flex: 1;
  margin-top: 4px;
}
.list-item-distance {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  color: #61666d;
  width: 285px;
}
.item-select {
  flex: 1;
  position: relative;
}
.item-select-icon {
  position: absolute;
  right: 4px;
  top: 2px;
}
.item-select-line {
  height: 1px;
  margin-left: 16px;
  width: 100%;
  background-color: #e3e5e7;
}
.location-icon {
  width: 10px;
  height: 10px;
}
.address-desc {
  margin-left: 12px;
}
</style>
