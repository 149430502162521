export default {
  data() {
    return {
      bgStyle: {}
    }
  },
  watch: {
    countOptions(newValue, oldValue) {
      this.changeStyle()
    }
  },
  mounted() {
    this.changeStyle()
  },
  computed: {
    color() {
      return this.countOptions?.color || '#0A8AFA'
    }
  },
  methods: {
    changeStyle() {
      const ele = this.$refs.msg
      if (!ele) return
      try {
        const style = getComputedStyle(ele)
        this.$nextTick(() => {
          this.bgStyle = {
            width: style.width,
            height: style.height
          }
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
