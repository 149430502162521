<template>
  <CcDownload
    :data="data"
    :default-app="{
      name: '此处填写标题',
      description: '此处填写需求描述，需求描述内容支持两行显示'
    }"
    :show-logo="false"
  />
</template>
<script>
import CcDownload from '../components/Download'

export default {
  name: 'SimpleLogoButtonComp',
  components: {
    CcDownload
  },
  provide() {
    return {
      compName: this.component.name,
      compId: this.component.id
    }
  },
  props: {
    component: {
      type: Object,
      default: null
    },
    download: {
      type: Object,
      default: null
    }
  },
  computed: {
    data() {
      return this.component.data || {}
    }
  }
}
</script>
