<template>
  <div class="tel-wrap">
    <template v-if="(useExpClick ? isUseClickFill : useLocalPhone) && !isFromVideo">
      <NormalInput
        v-model="innerData.value"
        :maxlength="11"
        :placeholder="placeholder"
        class="tel-code"
        @change="change"
        @focus="focus"
      >
        <van-button
          slot="append"
          :disabled="count > 0"
          :style="{ color: themeColor }"
          class="tel-send-btn"
          plain
          tag="a"
          type="info"
          @click.stop="setLocalPhone"
          >{{ useExpClick ? '使用本机号码' : '使用本机号码' }}
        </van-button>
      </NormalInput>
      <div v-if="useLocalPhone" class="local-number-tips">
        <span>使用本机号码即同意</span>
        <span @click="href(carrierInfo.link)">《中国{{ carrierInfo.name }}服务条款》</span>
      </div>
    </template>
    <template v-else>
      <NormalInput
        v-model="innerData.value"
        :maxlength="11"
        :placeholder="placeholder"
        border
        class="tel-num"
        @focus="focus"
      >
        <div v-show="showPrefix" slot="prepend" class="tel-pre">{{ data.prefix }}</div>
      </NormalInput>
      <NormalInput
        v-if="data.is_submit_validate && !inBiliApp && !isTemplatePre"
        v-model="data.extra_value"
        :maxlength="10"
        class="tel-code mt14"
        placeholder="验证码输入"
        type="number"
        @change="change"
        @focus="focus"
      >
        <van-button
          slot="append"
          :disabled="count > 0"
          :style="{ color: themeColor }"
          class="tel-send-btn"
          plain
          tag="a"
          type="info"
          @click.stop="sendCode()"
        >
          <template v-if="!count">发送验证码</template>
          <template v-else>重新发送({{ count }}s)</template>
        </van-button>
      </NormalInput>
    </template>
    <phone-verify
      v-if="inBiliApp"
      ref="verify"
      :tel-number="data.value"
      :phone-channel="data.phone_channel"
      @success="onCodeSuccess"
    ></phone-verify>
  </div>
</template>
<script>
import NormalInput from './NormalInput'
import { biliBridge, inBiliApp } from '@bilibili/js-bridge'
import { serializeBPhone, serializePhoneExt, serializeTelCode } from './utils'
import PhoneVerify from './PhoneVerify'
import {
  MINI_click_use_local_phone,
  MINI_click_use_local_token,
  MINI_click_use_sdk_token,
  MINI_local_phone_recode_token_fail,
  MINI_local_phone_recode_token_suc,
  MINI_local_phone_token_fail,
  MINI_local_phone_token_suc,
  MINI_login_phone_fail,
  MINI_login_phone_success,
  MINI_submit_phone_equal,
  MINI_tel_code_fail,
  MINI_tel_code_success,
  MINI_use_local_phone_fail,
  MINI_use_local_phone_suc
} from '../../constants/reportEvents'
import { replaceUrl } from '@/utils'
import { bPhoneExpEnum, phoneChannelEnum } from './constants'
import {
  WARN_page_get_phone_number_fail,
  WARN_page_get_phone_code_fail
} from '@/canvas/constants/warnEvents'

// 是否勾选过使用历史信息
const allowHistoryStatus = {
  NOT_SET: 0,
  ALLOW: 1,
  NOT_ALLOW: 2
}
// 是否显示过弹窗
const showToastStatus = {
  NOT_SHOW: 0,
  SHOW: 1
}
// 手机号是否验证
const phoneValidateStatus = {
  NOT_VALIDATE: 0,
  VALIDATE: 1
}
// token有效时长9分钟
const TOKEN_TIME = 9 * 60 * 1000
// 6.64
const ANDROID_SUPPORT_VERSION = 6640000
const IOS_SUPPORT_VERSION = 66400000
// 运营商
const carriersMap = {
  MOBILE: 'mobile',
  UNICOM: 'unicom',
  TELECOM: 'telecom'
}
const carriersInfo = {
  [carriersMap.MOBILE]: {
    name: '移动',
    link: 'https://wap.cmpassport.com/resources/html/contract.html'
  },
  [carriersMap.UNICOM]: {
    name: '联通',
    link: 'https://opencloud.wostore.cn/authz/resource/html/disclaimer.html?fromsdk=true'
  },
  [carriersMap.TELECOM]: {
    name: '电信',
    link: 'https://e.189.cn/sdk/agreement/show.do?order=2&type=main&appKey=&hidetop=&returnUrl='
  }
}
const carriers = [carriersMap.MOBILE, carriersMap.UNICOM, carriersMap.TELECOM]

export default {
  name: 'TelephoneComp',
  components: {
    PhoneVerify,
    NormalInput
  },
  inject: ['pageId'],
  props: {
    data: {
      type: Object,
      default: null
    },
    telCompInfo: {
      type: Object,
      default: null
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    showPrefix: {
      type: Boolean,
      default: true
    },
    themeColor: {
      type: String,
      default: ''
    },
    isFromVideo: {
      type: Boolean,
      default: false
    },
    history: {
      type: Object,
      default: () => ({})
    },
    bPhoneInfo: {
      type: Object,
      default: () => ({})
    },
    isTemplatePre: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      count: 0,
      timeout: null,

      showRealInput: true,
      showAgreementModal: false,
      innerData: {
        value: ''
      },
      model: '',
      build: '',
      localPhoneInfo: {},
      isUseLocalPhone: false, // 本机号码环境可用性，是使用本机号码众多条件中的一个
      localCarrier: '', // 运营商
      inBiliApp,
      isUseBPhone: false, // b站手机号可用性
      useExpClick: window._globalExp?.b_login_phone === bPhoneExpEnum.EXP_CLICK
    }
  },
  computed: {
    useOriginal() {
      const {
        number,
        allow_history: isAllowHistory,
        is_phone_validate: isPhoneValidate
      } = this.history

      return (
        isAllowHistory === allowHistoryStatus.ALLOW &&
        isPhoneValidate === phoneValidateStatus.VALIDATE &&
        number
      )
    },
    isSupportLocalPhone() {
      const supportAndroid = this.model === 'android' && this.build >= ANDROID_SUPPORT_VERSION
      const supportIOS = this.model === 'iphone' && this.build >= IOS_SUPPORT_VERSION
      return inBiliApp && (supportAndroid || supportIOS)
    },
    useLocalPhone() {
      return this.isSupportLocalPhone && this.isUseLocalPhone && !this.useOriginal
    },
    carrierInfo() {
      return carriersInfo[this.localCarrier] || {}
    },
    usingBPhone() {
      return this.data.phone_channel === phoneChannelEnum.B_PHONE
    },
    usingLocalPhone() {
      return this.data.phone_channel === phoneChannelEnum.LOCAL_PHONE
    },
    usingUserInput() {
      return !this.usingBPhone && !this.usingLocalPhone
    },
    isUseClickFill() {
      // 一键填写可用性
      return this.isUseLocalPhone || window.alita?.appInfo?.mid
    }
  },
  watch: {
    'telCompInfo.autoFill'(newValue) {
      this.onAutoFillChange(newValue)
    },
    'history.allow_history'(newValue) {
      if (!this.isFromVideo) {
        replaceUrl({ allow_history: newValue || 0 })
      }
    },
    'innerData.value'(newValue, oldValue) {
      // 回退
      if (oldValue && newValue.length < oldValue.length && newValue.includes('*')) {
        this.forceSetPhoneChannel()
        this.innerData.value = ''
      } else if (!newValue.includes('*')) {
        this.forceSetPhoneChannel()
        this.data.value = newValue // 更新电话号码
        this.change()
      } else {
        this.change()
      }
    },
    useLocalPhone(val) {
      this.telCompInfo.useLocalPhone = val
    },
    history(newValue, oldValue) {
      if (oldValue.isEmpty) {
        this.useHistory()
      }
    }
  },
  created() {
    this.getModel()
    this.innerData = { ...this.data, value: '' }
  },
  methods: {
    change() {
      this.$emit('change')
    },
    focus() {
      this.$emit('focus')
    },
    getFakeNumber(number = '') {
      return number ? number.slice(0, 3) + '******' + number.slice(9, 11) : ''
    },
    useHistory() {
      if (this.history.allow_history === allowHistoryStatus.ALLOW) {
        this.telCompInfo.autoFill = true
      }
      // 当勾选历史信息&有储存过已验证手机号时，不使用本地手机号
      if (!this.useOriginal) {
        this.getMobileNumberInfo().catch(() => {})
      }
    },
    doAutoFill({ number, name }) {
      if (number) {
        this.innerData.value = this.getFakeNumber(number)
        this.data.value = number
        this.$emit('on-auto-fill-phone', this.data)
      }
      if (name) {
        this.$emit('on-auto-fill-name', name)
      }
    },
    sendCode(code) {
      if (!this.data.value) {
        return this.$dialog.alert({
          message: '请输入手机号码',
          className: 'max-index'
        })
      }
      this.count = 30
      this.calculate()

      let telCodeExtra = {}
      if (this.data.phoneChannel === phoneChannelEnum.B_PHONE) {
        telCodeExtra = {
          phone_channel: this.data.phoneChannel,
          mid: window?.alita?.appInfo?.mid
        }
      }
      this.$request
        .post('v1/form/ttcode', serializeTelCode(this.data.value, code, telCodeExtra))
        .then(({ code, message }) => {
          if (code === 0 || message === '验证码已发送，请注意查收。') {
            this.$reportUI(MINI_tel_code_success)
            return this.$toast('验证码发送成功')
          }

          return Promise.reject(message)
        })
        .catch((error) => {
          console.error(error)
          this.$dialog.alert({
            message: '获取验证码失败，请稍后再试',
            className: 'max-index'
          })
          this.$reportUI(MINI_tel_code_fail)
          this.$reportWarn(WARN_page_get_phone_code_fail)
        })
    },
    calculate() {
      if (this.count <= 0) {
        clearTimeout(this.timeout)
        this.timeout = null
        this.count = 0
        return
      }

      clearTimeout(this.timeout)
      this.count--
      this.timeout = setTimeout(this.calculate, 1000)
    },
    onAutoFillChange(autoFill) {
      // 已勾选，且未修改过默认填充的手机号
      if (autoFill) {
        this.doAutoFill(this.history)
      }
      // 勾选行为状态
      this.$set(
        this.history,
        'allow_history',
        autoFill ? allowHistoryStatus.ALLOW : allowHistoryStatus.NOT_ALLOW
      )
    },
    handleAllowHistory(showToast) {
      const { allow_history, allow_history_toast } = this.history
      // 未勾选且未显示过弹窗
      if (!this.telCompInfo.autoFill && allow_history_toast === showToastStatus.NOT_SHOW) {
        showToast && showToast()
      } else {
        this.$request
          .put(
            'v1/form_data/phone',
            serializePhoneExt({
              user_info: { allow_history }
            })
          )
          .catch((e) => {
            console.log(e)
          })
      }
    },
    href(url) {
      url && window.open(url)
    },
    validate() {
      // 判断是否弹验证码弹窗
      const {
        number,
        is_phone_validate: isValid,
        need_validate_phone_no: needValidatePhoneNo
      } = this.history
      const { need_check_phone_no: needValidateBPhoneNo } = this.bPhoneInfo || {}

      // 无需验证 或 在pc端（使用验证码input框）
      if (!inBiliApp || !this.data.is_submit_validate) {
        return true
      }
      // 使用本机号码
      if (this.usingLocalPhone && this.data.value.includes('*')) {
        return true
      }
      // 使用b站手机号 且 不在必须验证的黑名单中
      if (this.usingBPhone && this.data.value.includes('*') && !needValidateBPhoneNo) {
        return true
      }
      // 未改变号码 且 历史手机号已验证过 且 不在必须验证的黑名单中
      if (this.data.value && this.data.value === number && !!isValid && !needValidatePhoneNo) {
        return true
      }
      // 上述情况均不满足时，走智能验证
      this.$refs.verify && this.$refs.verify.open()
      return false
    },
    getMobileNumberInfo() {
      return new Promise((resolve, reject) => {
        if (!this.isSupportLocalPhone) return reject(new Error(''))

        try {
          biliBridge.callNative({
            method: 'cm.getMobileNumberInfo',
            data: {},
            callback: (res) => {
              const { code, data, msg } = res
              if (code === 0) {
                const { carrier = '', number = '' } = data || {}
                // 仅仅移动联通电信
                this.$reportUI(MINI_use_local_phone_suc)
                if (carriers.includes(carrier) && number) {
                  this.localCarrier = carrier
                  // 开启使用本机号码功能
                  this.isUseLocalPhone = true
                  resolve()
                } else {
                  reject(new Error(''))
                }
              } else {
                this.$reportUI(MINI_use_local_phone_fail, { reason: msg })
                reject(new Error(''))
              }
            }
          })
        } catch (e) {
          console.log('e', e)
          this.$reportUI(MINI_use_local_phone_fail, { reason: JSON.stringify(e) })
          reject(new Error(''))
        }
      })
    },
    getMobileAuthInfo(isRecode = false) {
      return new Promise((resolve, reject) => {
        try {
          biliBridge.callNative({
            method: 'cm.getMobileAuthInfo',
            data: {},
            callback: (res) => {
              const { code, data, msg } = res
              if (code === 0) {
                this.localPhoneInfo = {
                  token: data.token,
                  number: data.number,
                  getTokenTime: Date.now()
                }
                this.data.value = data.number
                this.innerData.value = data.number
                this.forceSetPhoneChannel(phoneChannelEnum.LOCAL_PHONE)
                this.data.encrypted_phone_vo = {
                  token: data.token,
                  build: this.build,
                  buvid: data.buvid,
                  local_id: data.buvid,
                  device: 'phone',
                  origin: data.origin,
                  auth_code: data.auth_code,
                  carrier: data.carrier,
                  mid: data.mid,
                  from_url: this.pageId
                }
                if (data.carrier_ver) {
                  this.data.encrypted_phone_vo.carrier_ver = data.carrier_ver || ''
                }
                this.$reportUI(
                  isRecode ? MINI_local_phone_recode_token_suc : MINI_local_phone_token_suc
                )
                this.$reportUI(MINI_click_use_sdk_token)
                resolve()
              } else {
                this.$toast(
                  isRecode
                    ? '抱歉，本机号码解析失败，请手动填写'
                    : '获取本机号码失败，请手动填写手机号码'
                )
                this.$reportUI(
                  isRecode ? MINI_local_phone_recode_token_fail : MINI_local_phone_token_fail,
                  {
                    reason: msg
                  }
                )
                reject(new Error())
              }
            }
          })
        } catch (e) {
          console.log('e', e)
          this.$toast(
            isRecode ? '抱歉，本机号码解析失败，请手动填写' : '获取本机号码失败，请手动填写手机号码'
          )
          this.$reportUI(
            isRecode ? MINI_local_phone_recode_token_fail : MINI_local_phone_token_fail,
            { reason: e }
          )
          reject(new Error())
        }
      })
    },
    reQueryLocalPhoneToken() {
      const { token = '', number = '' } = this.localPhoneInfo
      // 提交时未修改过本机号码
      if (number && token && this.innerData.value.includes('*')) {
        this.getMobileAuthInfo(true)
      }
    },
    reportEqualTel() {
      if (this.data.value === this.history.number) {
        this.$reportUI(MINI_submit_phone_equal)
      }
    },
    setLocalPhone() {
      this.$reportUI(MINI_click_use_local_phone)

      // 不支持本地手机号则使用B站手机号
      if (!this.isUseLocalPhone) return this.getThenSetBPhone()

      const { token = '', number = '', getTokenTime = 0 } = this.localPhoneInfo
      const taskTime = Date.now() - getTokenTime
      // 因为每天的调用时间有限制，若已经获取过手机与token则直接用原有的，因为有9分钟过期时间所以9分钟重新获取一次
      if (token && number && taskTime < TOKEN_TIME) {
        this.$reportUI(MINI_click_use_local_token)
        this.data.value = number
        this.innerData.value = number
      } else {
        this.getMobileAuthInfo().catch(() => {
          this.getThenSetBPhone()
        })
      }
    },
    getModel() {
      if (window.alita) {
        window.alita.getNativeInfo().then((res) => {
          this.model = res.model
          this.build = res.build_id
        })
      }
    },
    onCodeSuccess(code) {
      this.data.extra_value = code
      this.$emit('submit')
    },
    setNumber(number, phoneChannel) {
      this.data.value = number
      this.innerData.value = number
      this.forceSetPhoneChannel(phoneChannel)
    },
    setBPhone() {
      if (!this.useExpClick) return
      if (!this.bPhoneInfo?.hide_tel) return
      this.setNumber(this.bPhoneInfo?.hide_tel, phoneChannelEnum.B_PHONE)
    },
    getBPhone() {
      if (!this.useExpClick) return Promise.reject(new Error())
      return this.$request
        .post('v1/form_data/user/info', serializeBPhone())
        .then((res) => {
          this.isUseBPhone = true
          this.bPhoneInfo.hide_tel = res?.data?.hide_tel
          this.bPhoneInfo.need_check_phone_no = res?.data?.need_check_phone_no

          res?.data?.hide_tel
            ? this.$reportUI(MINI_login_phone_success)
            : this.$reportUI(MINI_login_phone_fail, { reason: 1 })
        })
        .catch(() => {
          this.$reportUI(MINI_login_phone_fail, { reason: 0 })
          this.$reportWarn(WARN_page_get_phone_number_fail, { reason: 0 })
        })
    },
    getThenSetBPhone() {
      if (this.isUseBPhone) {
        this.setBPhone()
      } else {
        this.getBPhone().then(() => {
          this.setBPhone()
        })
      }
    },
    forceSetPhoneChannel(phoneChannel) {
      this.$set(this.data, 'phone_channel', phoneChannel || phoneChannelEnum.USER_INPUT)
    }
  }
}
</script>
<style lang="less">
.tel-wrap {
  width: 100%;
  .tel-num {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 44px;
    .cc-form-input {
      flex: 1;
    }
    .tel-pre {
      flex: 0 0 56px;
      margin: -10px 12px -10px -12px;
      line-height: 40px;
      text-align: center;
      border-right: 1px solid #dddee0;
      color: #666;
    }
  }
  .tel-code {
    display: flex;
    box-sizing: border-box;
    height: 44px;
    .tel-send-btn {
      flex: 0 0 116px;
      margin: -10px -12px -10px 0;
      white-space: nowrap;
      height: 42px;
      line-height: 40px;
      text-align: center;
      border: none;
      border-left: 1px solid #e6e6e6;
      border-radius: 0;
      background: transparent;
      color: #0a8afa;
    }
    .cc-form-input {
      flex: 1;
    }
  }
}
.mt14 {
  margin-top: 14px !important;
}
</style>
<style lang="less" scoped>
.tel-wrap {
  .local-number-tips {
    margin-top: 10px;
    font-size: 12px;
    line-height: 12px;
    color: #999999;
    span:nth-of-type(2) {
      color: #0a8afa;
      cursor: pointer;
    }
  }
}
</style>
